import { AuthService } from '~/core/services/auth.service';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { AppInsightsService } from '../../core/services/app-insights.service';
import { LoaderService } from '../../core/services/loader.service';
import { PullToRefreshService } from '../../core/services/pull-to-refresh.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  public constructor(
    private router: Router,
    private pullToRequestService: PullToRefreshService,
    private loaderService: LoaderService,
    private injector: Injector
  ) { }

  public handleError(error: any): void {
    const appInsights = this.injector.get(AppInsightsService);
    appInsights.trackException(error);

    this.loaderService.clear();
    this.pullToRequestService.finishLoading();
    // this.router.navigateByUrl('/error');
    this.router.navigateByUrl('/home');

    // IMPORTANT: Rethrow the error otherwise it gets swallowed
    throw error;
  }
}
