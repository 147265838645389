
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { TargetV2 } from 'src/app/shared/models/targetV2.model';

export interface ITargetV2State extends EntityState<TargetV2> {
  targetV2: TargetV2
}

export function createInitialState(): ITargetV2State {
  return {
      targetV2: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'targetV2' })
export class TargetV2Store extends EntityStore<ITargetV2State> {
  constructor() {
      super(createInitialState());
  }
}
