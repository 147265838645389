import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '~/core/services/auth.service';
import { TrendQuery } from '~/store/trend/trend.query';
import { TrendStore } from '~/store/trend/trend.store';
import { TokenQuery } from '../../store/token/token.query';
import { CompiledAverageMonthlyData } from '../models/compiled-average-monthly-data.model';
import { Configuration } from '../models/configuration.model';
import { MonthlyCompliance } from '../models/monthly-compliance.model';
import { Trend } from '../models/trend.model';
import { CompetencyApiBaseService } from './base/competency-api-base.service';
import { Chart } from 'chart.js';

@Injectable({ providedIn: 'root' })
export class MyTrendsService extends CompetencyApiBaseService<any> {

  private trend$: Trend;
  private _trend: Trend;
  private useMobileMode: boolean = null;
  private chartColumnMonthResolution = 24;
  private chartLabels = [];
  private chartBackgroundColor = [
    'rgba(151, 151, 151, 0.5)',
    'rgba(151, 151, 151, 0.5)',
    'rgba(151, 151, 151, 0.5)',
    'rgba(151, 151, 151, 0.5)',
    'rgba(151, 151, 151, 0.5)',
    'rgba(151, 151, 151, 0.5)',
    'rgba(151, 151, 151, 0.5)',
    'rgba(151, 151, 151, 0.5)',
    'rgba(151, 151, 151, 0.5)',
    'rgba(151, 151, 151, 0.5)',
    'rgba(151, 151, 151, 0.5)',
    'rgba(151, 151, 151, 0.5)',
    'rgba(151, 151, 151, 0.5)',
    'rgba(151, 151, 151, 0.5)',
    'rgba(151, 151, 151, 0.5)',
    'rgba(151, 151, 151, 0.5)',
    'rgba(151, 151, 151, 0.5)',
    'rgba(151, 151, 151, 0.5)',
    'rgba(105, 56, 191, 0.5)',
    'rgba(105, 56, 191, 0.5)',
    'rgba(105, 56, 191, 0.5)',
    'rgba(105, 56, 191, 0.5)',
    'rgba(105, 56, 191, 0.5)',
    'rgba(105, 56, 191, 0.5)'
  ];

  public lastSixMonthsAverage = 0;
  public firstEighteenMonthsAverage = 0;
  public hasTrendData = false;
  public chartHeight = '600px';
  public chartWidth = '100%';

  constructor(
    httpClient: HttpClient,
    config: Configuration,
    token: TokenQuery,
    private authService: AuthService,
    private trendStore: TrendStore,
    private trendQuery: TrendQuery
  ) {
    super(httpClient, config, token);
    this.endpoint = 'trends';
  }

  private prepareChartData(loadedTrendData: Trend): void {
    const latestDate = this.getLatestDateFromArray(loadedTrendData);
    this.sortTrendData(loadedTrendData);
    this.lastSixMonthsAverage = this.getFirstSixMonthAverage(loadedTrendData);
    this.firstEighteenMonthsAverage = this.getLastEighteenMonthAverage(loadedTrendData);
    this.fillInTrendData(loadedTrendData);
    this.chartLabels = this.getMonthLabelsToChart(latestDate);
  };

  private getLatestDateFromArray(trendData: Trend): string {
    let latestDate = '0';
    trendData.monthlyCompliance.forEach((mcItem) => {
      if (parseInt(mcItem.month, 10) > parseInt(latestDate, 10)) {
        latestDate = mcItem.month;
      }
    });
    return latestDate;
  }

  private sortTrendData(trendData: Trend): void {
    trendData.monthlyCompliance.sort((a, b) => {
      return (parseInt(a.month, 10) - parseInt(b.month, 10));
    })
  }

  private getFirstSixMonthAverage(loadedTrendData: Trend): number {
    const monthlyData = loadedTrendData.monthlyCompliance;

    let monthlyAverage = 0;
    let monthsCounted = 0;
    const numbersToCount = monthlyData.length - 1;

    if (monthlyData.length >= 1) {
      for (let i = numbersToCount; i >= 0; i--) {
        if (monthsCounted < 6) {
          monthlyAverage += parseInt(monthlyData[i].compliance, 10);
          monthsCounted++;
        }
      }
    }
    return monthsCounted > 0 ? Math.round(monthlyAverage / monthsCounted) : 0;
  }

  private getLastEighteenMonthAverage(loadedTrendData: Trend): number {
    const monthlyData = loadedTrendData.monthlyCompliance;

    let monthlyAverage = 0;
    let monthsCounted = 0;
    const numbersToCount = monthlyData.length - 1;

    if (monthlyData.length > 6) {
      for (let i = numbersToCount; i >= 0; i--) {
        monthsCounted++;
        if (monthsCounted > 6) {
          monthlyAverage += parseInt(monthlyData[i].compliance, 10);
        }
      }
    }
    return monthsCounted > 6 ? Math.round(monthlyAverage / (monthsCounted - 6)) : 0;
  }

  private fillInTrendData(trendData: Trend): void {
    const trendLenght = trendData.monthlyCompliance.length;
    const numberOfMonthsToAdd = this.chartColumnMonthResolution - trendLenght;
    for (let i = 0; i < numberOfMonthsToAdd; i++) {
      trendData.monthlyCompliance.unshift(
        new MonthlyCompliance(
          {
            month: '',
            monthName: '',
            year: '',
            compliance: '0%',
            jobCount: 0
          }
        )
      )
    }
  }

  private getMonthLabelsToChart(latestDate: string): string[] {
    const arrayOfChartLabelsToReturn = [];

    const latestYear = latestDate.slice(0, 4);
    const latestMonth = latestDate.slice(4);

    const dateToManipulate = this.subtractMonthsToDate(
      this.chartColumnMonthResolution, new Date(parseInt(latestYear, 10), parseInt(latestMonth, 10))
    );

    for (let i = 0; i < this.chartColumnMonthResolution; i++) {
      arrayOfChartLabelsToReturn.push(dateToManipulate.toLocaleDateString('en-us', { year: '2-digit', month: 'short' }));
      this.addMonthsToDate(1, dateToManipulate);
    }

    return arrayOfChartLabelsToReturn;
  }

  private subtractMonthsToDate(numberOfMonths: number, date: Date): Date {
    date.setMonth(date.getMonth() - numberOfMonths);
    return date;
  };

  private addMonthsToDate(numberOfMonths: number, date: Date): Date {
    date.setMonth(date.getMonth() + numberOfMonths);
    return date;
  };

  private setChart(loadedTrendData: Trend): void {
    let chartStatus = Chart.getChart("yourTrendsChart"); // <canvas> id
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }

    const querySelectorToUse = this.useMobileMode ? '.mobile-trend .your-trends-chart' : '.desktop-trend .your-trends-chart';
    const canvas = document.querySelectorAll(querySelectorToUse)[0] as HTMLCanvasElement; const ctx = canvas.getContext('2d');
    const compiledMonthlyData = this.getCompileMonthlyChartData(loadedTrendData);
    const compiledAverageData = this.compiledAverageData();

    const yourTrendsChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: this.chartLabels,
        datasets: [
          {
            type: 'line',
            label: 'First 18 months',
            data: compiledAverageData.FirstEighteenMonthsAvg,
            backgroundColor: this.chartBackgroundColor,
            borderColor: 'rgba(50, 50, 50, 0.75)',
            fill: false,
            spanGaps: false,
            stepped: 'middle',
            borderWidth: 3,
            pointRadius: 0
          },
          {
            type: 'line',
            label: 'Last 6 months',
            data: compiledAverageData.LastSixMonthsAvg,
            backgroundColor: this.chartBackgroundColor,
            borderColor: 'rgba(0, 0, 100, 0.75)',
            fill: false,
            spanGaps: false,
            stepped: 'middle',
            borderWidth: 3,
            pointRadius: 0
          },
          {
            label: 'Monthly Compliance',
            data: compiledMonthlyData,
            backgroundColor: this.chartBackgroundColor,
            borderColor: this.chartBackgroundColor,
            borderWidth: 1
          }
        ]
      },
      options: {
        responsive: true,
        aspectRatio: 2,
        maintainAspectRatio: true,
        events: [],
        plugins: {
          legend: {
              display: false
          }
      },
        scales: {
          y: {
              max: 100,
              min: 0,
              ticks: {
                // forces step size to be 50 units
                stepSize: 25
              },
              beginAtZero: true
            }
        }
      }
    });
    //yourTrendsChart.canvas.parentNode.style.width = this.chartWidth;
  }

  private getCompileMonthlyChartData(trendData: Trend): number[] {
    let mappedComplianceData = [];

    if (trendData?.monthlyCompliance?.length > 0) {
      mappedComplianceData = trendData.monthlyCompliance.map((monthlyComplianceItem) => {
        return parseInt(monthlyComplianceItem.compliance, 10);
      });
    }
    return mappedComplianceData;
  }

  private compiledAverageData(): CompiledAverageMonthlyData {
    const numberOfInitialMonths = 18;

    const compiledAverageTrendData: CompiledAverageMonthlyData = {
      LastSixMonthsAvg: [],
      FirstEighteenMonthsAvg: []
    };

    for (let i = 0; i < this.chartColumnMonthResolution; i++) {
      if (i < numberOfInitialMonths) {
        compiledAverageTrendData.FirstEighteenMonthsAvg.push(this.firstEighteenMonthsAverage);
      } else {
        compiledAverageTrendData.FirstEighteenMonthsAvg.push(NaN);
      }

      if (i >= numberOfInitialMonths) {
        compiledAverageTrendData.LastSixMonthsAvg.push(this.lastSixMonthsAverage);
      } else {
        compiledAverageTrendData.LastSixMonthsAvg.push(NaN);
      }
    }
    return compiledAverageTrendData;
  }

  public initializeTrendChart(useMobileMode?: boolean, renderChart: boolean = true): void {
    if(useMobileMode === true || useMobileMode === false) {
      this.useMobileMode = useMobileMode;
    }
    this.hasTrendData = false;
    this.lastSixMonthsAverage = 0;
    this.firstEighteenMonthsAverage = 0;

    this.trendQuery.select().subscribe((state) => {
      this.trend$ = state.trend;
    });

    // Break Reference
    this._trend = JSON.parse(JSON.stringify(this.trend$));

    const loadedDataIsNotEmpty = this._trend?.monthlyCompliance?.length > 0;

    if (loadedDataIsNotEmpty) {
      this.hasTrendData = true;
      this.prepareChartData(this._trend);

      if(renderChart) {
        this.setChart(this._trend);
      }
    } else {
      this.hasTrendData = false;
    }
  }

  public getTrends(): Observable<Trend> {
    const ginNumber = this.authService.getGinNumber();
    return this.getPatSingleCustom(`${this.config.newApiUrl}/${this.endpoint}/get?GinNumber=${ginNumber}`)
      .pipe(
        map(
          (dto) => {
            if (dto) {
              const trend = new Trend(dto);
              this.trend$ = trend;
              this.trendStore.update({ trend });
              this.initializeTrendChart(null, false);
              return trend;
            }
            return null;
          }
        )
      )
  }
}
