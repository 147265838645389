import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ElementExpiration } from '../../../../shared/models/element-expiration.model';
import { isMobileSize } from '../../../../shared/utils/navigator';
import { PcpRequirements } from '../../../../shared/models/pcp-requirements.model';

@Component({
  selector: 'pcp-expiration-details',
  templateUrl: './expiration-details.component.html',
  styleUrls: ['./expiration-details.component.scss']
})
export class ExpirationDetailsComponent implements OnChanges {
  private awardedOnDate: Date;
  daysRemaining: number | Date;

  public panelOpenState = isMobileSize() ? false : true;
  @Input() public pcpRequirements: PcpRequirements[];
  @Output() onClickOpenDetailDialog = new EventEmitter<{data: any, type: string, awardedOnDate: Date }>();
  public message: string;
  public expirations: Array<ElementExpiration> = [];

  constructor(
    public dialog: MatDialog,
  ) { }

  public ngOnChanges(): void {
    this.panelOpenState = isMobileSize() ? false : true;
    this.getDaysRemaining(this.pcpRequirements);
  }


  private getDaysRemaining(expiration: any): void {
    if (expiration) {
      expiration.filter((obj) => {
        return obj.expiresOn != null || obj.expiresOn != undefined
      }).map((obj) => { return obj });

      expiration.forEach(element => {
        if (element.expiresOn) {
          const _date = new Date(element.expiresOn)
          const time = (_date.getTime() - new Date().getTime());
          const days = Math.round(time / (1000 * 60 * 60 * 24));
          this.pcpRequirements.forEach(requirement => {
            if (requirement.requirementId === element.requirementId)
              requirement.expiresOn = days.toString();
          });
        }
      });
    }
  }

  public openDialog(data: any): void {
    this.onClickOpenDetailDialog.emit({
      data: data,
      type: 'expiration',
      awardedOnDate: this.awardedOnDate
    });
  }

  changeExpiresOnToNumber(expiresOn: string) {
    return Number(expiresOn);
  }

  promotionInfoItemLabelByExpiration(pcpRequirement: PcpRequirements) {
    if (Number(pcpRequirement.expiresOn) < 0) {
      return 'Expired';
    }

    return 'Remaining';
  }

  promotionInfoItemValueByExpiration(pcpRequirement: PcpRequirements) {
    if (Number(pcpRequirement.expiresOn) < 0) {
      return '';
    }

    return `${pcpRequirement.expiresOn} days`;
  }
}
