import { Items } from './../models/competency-details.model';
import { Injectable } from '@angular/core';
import { ResultResponseType } from "~/shared/enums/result-response-type.enum";
import { ResponseItemDto } from './dtos/response-item.dto';
import { ErrorResponse } from './dtos/error.dto';

@Injectable({
  providedIn: 'root'
})
export class ResponseItemService<T> {

  constructor() { }

  createSuccessResponseItem<T>(item: T) {
    return new ResponseItemDto({
      result: ResultResponseType.success,
      item: item
    });
  }

  createSuccessArrayResponseItem<T>(items: T[]) {
    return new ResponseItemDto({
      result: ResultResponseType.success,
      items: items
    });
  }

  createErrorResponseItem<T>(error: T) {
    return new ResponseItemDto({
      result: ResultResponseType.error,
      error: error
    });
  }
}
