<section class="container">

  <div class="message-container">
    <p class="state-type" *ngIf="isLoading  === true">
      Processing
    </p>

    <p class="state-type" *ngIf="isSuccess()">
      Success
    </p>

    <p class="state-type" *ngIf="isError()">
      Error
    </p>

    <p *ngIf="message" class="message">
      {{message}}
    </p>
  </div>

  <div class="state-container">

    <mat-progress-spinner *ngIf="isLoading === true" [diameter]="48" mode="indeterminate"></mat-progress-spinner>

    <svg  class="state-success" *ngIf="isSuccess()" xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48">
      <path d="m419-285 291-292-63-64-228 228-111-111-63 64 174 175Zm60.679 226q-86.319 0-163.646-32.604-77.328-32.603-134.577-89.852-57.249-57.249-89.852-134.57Q59-393.346 59-479.862q0-87.41 32.662-164.275 32.663-76.865 90.042-134.438 57.378-57.574 134.411-90.499Q393.147-902 479.336-902q87.55 0 164.839 32.848 77.288 32.849 134.569 90.303 57.281 57.454 90.269 134.523Q902-567.257 902-479.458q0 86.734-32.926 163.544-32.925 76.809-90.499 134.199-57.573 57.39-134.447 90.053Q567.255-59 479.679-59Z"/>
    </svg>

    <svg class="state-error" *ngIf="isError()" xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48">
      <path d="m338-290 142-142 142 142 48-48-142-142 142-142-48-48-142 142-142-142-48 48 142 142-142 142 48 48ZM479.679-59q-86.319 0-163.646-32.604-77.328-32.603-134.577-89.852-57.249-57.249-89.852-134.57Q59-393.346 59-479.862q0-87.41 32.662-164.275 32.663-76.865 90.042-134.438 57.378-57.574 134.411-90.499Q393.147-902 479.336-902q87.55 0 164.839 32.848 77.288 32.849 134.569 90.303 57.281 57.454 90.269 134.523Q902-567.257 902-479.458q0 86.734-32.926 163.544-32.925 76.809-90.499 134.199-57.573 57.39-134.447 90.053Q567.255-59 479.679-59Z"/>
    </svg>

    <button class="button-ok" *ngIf="!isLoading" (click)="closeDialog()">
      OK
    </button>

  </div>


</section>
