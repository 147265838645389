import { ChangeDetectorRef, Component, ElementRef, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { Subject, throwError } from 'rxjs';
import { catchError, filter, takeUntil } from 'rxjs/operators';
import { allowedGins } from './configs/dev-allowed-gins-config';
import { AppInsightsService } from './core/services/app-insights.service';
import { AuthService } from './core/services/auth.service';
import { LoaderService } from './core/services/loader.service';
import { PageDataService } from './core/services/page-data.service';
import { PullToRefreshComponent } from './shared/components/pull-to-refresh/pull-to-refresh.component';
import { Configuration } from './shared/models/configuration.model';
import { CompetencyReportingService } from './shared/services/competency-reporting.service';
import { PromotionService } from './shared/services/promotion.service';
import { UserGraphService } from './shared/services/user-graph.service';
import { EnvironmentService } from './shared/services/environment.service';
import { MyTrendsService } from './shared/services/my-trends.service';
import { TargetService } from './shared/services/target.service';
import { TokenQuery } from './store/token/token.query';
import { UserSentimentSurveyService } from './core/services/user-sentiment-survey.service';
import { TokenStore } from './store/token/token.store';
import { LocalStorageKeys } from './shared/constants/local-storage-keys';
import { ImpersonateWorkflow } from '@des-cms/impersonate-library';

@Component({
  selector: 'pcp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();

  public pageTitle: string;
  public isHome = false;
  public showBackButton;
  public showHomeButton;
  public showFooterMenu;
  public showHeader;
  public disablePullToRefresh;
  public hideOfflineNotification;
  public finishedFirstLoading = false;
  public allComplete = false;
  public userName:string;

  @ViewChild('pullToRefresh') public pullToRefresh: PullToRefreshComponent;
  @ViewChild('main') public main: ElementRef;

  constructor(
    private pageDataService: PageDataService,
    private changeDetector: ChangeDetectorRef,
    private authService: AuthService,
    private router: Router,
    private loaderService: LoaderService,
    private injector: Injector,
    private broadcastService: MsalBroadcastService,
    private promotionService: PromotionService,
    private userGraphService: UserGraphService,
    private competencyReportingService: CompetencyReportingService,
    private configuration: Configuration,
    private environmentService: EnvironmentService,
    private trendsService: MyTrendsService,
    private targetsService: TargetService,
    private tokenQuery: TokenQuery,
    private userSentimentSurveyService: UserSentimentSurveyService,
    private tokenStore: TokenStore,
    private impersonateLibrary: ImpersonateWorkflow
  ) {
    //
  }

  public async ngOnInit() {
    await this.authService.msalInstance.initialize();
    if (this.configuration.isUnderMaintenance === 'true') {
      this.router.navigateByUrl('under-maintenance');
      return;
    }
    this.msalObservable();
    this.bindMsalEvents();

    this.pageDataService.get().subscribe((
      {
        title,
        showBackButton,
        showHomeButton,
        showFooterMenu,
        showHeader,
        disablePullToRefresh,
        hideOfflineNotification
      }) => {
      this.pageTitle = title;
      this.setIsHome();
      this.showBackButton = showBackButton === undefined ? false : showBackButton;
      this.showHomeButton = showHomeButton === undefined ? false : showHomeButton;
      this.showFooterMenu = showFooterMenu === undefined ? true : showFooterMenu;
      this.showHeader = showHeader === undefined ? true : showHeader;
      this.disablePullToRefresh = disablePullToRefresh === undefined ? false : disablePullToRefresh;
      this.hideOfflineNotification = hideOfflineNotification === undefined ? false : hideOfflineNotification;
    });

    if (this.userCanceledAADLogin()) {
      this.router.navigateByUrl('/login');
      return;
    }
  }

  public ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  private msalObservable() {
    this.broadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None && this.authService.msalInstance.getAllAccounts().length > 0
          ),
        takeUntil(this._destroying$),
        catchError((err) => null)
      )
      .subscribe((data) => {
        this.authService.checkAndSetActiveAccount();
        this.authService.registerRedirectCallback()
          .subscribe(() => {
            this.firstLoad();
          });
      });
  }

  private setIsHome() {
    this.changeDetector.detectChanges();
  }

  private userCanceledAADLogin(): boolean {
    return window.location.href.indexOf('error=access_denied') >= 0;
  }

  private firstLoad(): void {

    const isFirstLoad = this.authService.isAuthenticated() && window.location.href.indexOf('access_token') < 0;
    if (!isFirstLoad) {
      return;
    }

    if (this.loaderService.isLoading()) {
      return;
    }

    this.loaderService.enqueue();

    const appInsights = this.injector.get(AppInsightsService);
    appInsights.setUserId(this.authService.getEmail());

    /* TODO: Remove after tests */
    const msalActiveAccount = this.authService.msalInstance.getActiveAccount();
    let employeeGin = msalActiveAccount?.idTokenClaims['employee_gin'];

    /* TODO: Remove after tests */
    const isDev = (location.href.indexOf('dev') >= 0 || location.href.indexOf('localhost') >= 0 || location.href.indexOf('qa') >= 0);
    if (isDev && !employeeGin) {
      employeeGin = '1014471';
    }

    if (!employeeGin || employeeGin == '') {
      this.logoutAndGoToLogin();
      return;
    }

    if(isDev){
      const msalActiveAccount = this.authService.msalInstance.getActiveAccount();
      let userId = msalActiveAccount?.idTokenClaims['oid'];
      this.impersonateLibrary.setUserId(userId);
      this.validUserGroup(employeeGin.toString());   
    }
    else {
      this.loadPCP(Number(employeeGin), isDev);
    }
  }

  private validUserGroup(employeeGin: string) {
    const access_token = localStorage.getItem(LocalStorageKeys.MsalAccessToken);
    this.impersonateLibrary.setAccessToken(access_token);
    this.impersonateLibrary.setGroupId(this.configuration.azureImpersonationGroupId);

    this.impersonateLibrary.validUserGroup().subscribe({
      next: resp => this.impersonateGinNumber(employeeGin.toString()),
      error: err => {
        this.loadPCP(Number(employeeGin.toString()), true);
      }
    });
  }

  private impersonateGinNumber(employeeGin: string) {
   var originalGin = employeeGin;
    employeeGin =  prompt('Which gin number would you like to use for tests?', employeeGin.toString());
    if (employeeGin !== null) {
      this.impersonateLibrary.setGinNumber(employeeGin);
      this.impersonateLibrary.validGinNumber().subscribe({
        next: resp => {
          if (resp?.value.length > 0) {
              alert(`Now you are working with gin ${employeeGin}`);
              this.loadPCP(Number(employeeGin.toString()), true);
          }
          else {
            alert(`The gin number ${employeeGin} does not exists`);
            this.loadPCP(Number(originalGin.toString()), true);
          }
        },
        error : err => {
        alert(`The gin number ${employeeGin} does not exists`);
        this.loadPCP(Number(originalGin.toString()), true);
      }
      }); 
     }
    else {
      this.loadPCP(Number(originalGin.toString()), true);
    }
  }

  private logoutAndGoToLogin() {
    this.authService.logout();
    this.router.navigateByUrl('/login');
  }

  private async loadPCP(gin?: number, isDev?: boolean): Promise<void> {

    var mail = this.authService.msalInstance.getActiveAccount()?.username;
    var userName = mail?.toString().substring(0, mail.indexOf('@'));
    this.authService.setGinNumber(Number(gin));
    this.environmentService.setEnvironment = isDev;
    this.tokenQuery.msalApiToken$.subscribe(token => {
      if (token && token !== '') {
 this.trendsService.getTrends().subscribe();
        this.targetsService.getTargetsV2().subscribe();
        this.competencyReportingService.getReporting(Number(gin), isDev,'')
          .pipe(catchError((error) => {
            this.finishedFirstLoading = true;
            this.loaderService.dequeue();

            throw throwError(error);
          }))
          .subscribe((pcps) => {
            if (pcps?.elements?.length === 0) {
                this.router.navigateByUrl('/no-data-found');
            } else {
              this.promotionService.getByGinNumber(Number(gin)).subscribe((promo) => {
                if (promo) {
                  this.allComplete = promo.promotionResult === 'success'; //TODO
                }
                this.finishedFirstLoading = true;
                this.loaderService.dequeue();
                this.router.navigateByUrl('/home');
              });
              this.userGraphService.getPhoto(this.authService.getEmail()).subscribe();
            }
          });
      }
    });

    this.tokenQuery.ussApiToken$.subscribe(ussToken => {
      if (ussToken && ussToken !== '') {
        this.userSentimentSurveyService.startUserSentimentSurveyFlow(userName);
      }
    });
  }

  private bindMsalEvents(): void {
    this.broadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE))
      .subscribe((payload) => {
        this.injector.get(AppInsightsService).trackException(payload);
      });
    this.broadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE))
      .subscribe((payload) => {
        this.injector.get(AppInsightsService).trackException(payload);
      });
    this.broadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.SSO_SILENT_FAILURE))
      .subscribe((payload) => {
        this.injector.get(AppInsightsService).trackException(payload);
      });
    this.broadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        localStorage.setItem(LocalStorageKeys.MsalAccessToken, result?.payload['accessToken'] );
        const payload = result.payload as AuthenticationResult;
        this.authService.msalInstance.setActiveAccount(payload.account);
      });
  }

  public onTouchStart(event: any) {
    if (this.main.nativeElement.scrollTop === 0) {
      event.preparePullToRefresh = true;
    }
  }

  redirectErrorAuth(): void {
    this.router.navigateByUrl('/login/auth-denied');
  }
}
