import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';

export interface ITokenState extends EntityState<string> {
  token: string;
  msalApiToken: string;
  intouchToken: string;
  esmToken: string;
  esmApiToken: string;
  casApiToken: string;
  catApitoken: string;
  ussApiToken: string;
}

export function createInitialState(): ITokenState {
  return {
    token: '',
    msalApiToken: '',
    intouchToken: '',
    esmToken: '',
    esmApiToken: '',
    casApiToken: '',
    catApitoken: '',
    ussApiToken: ''
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'token' })
export class TokenStore extends EntityStore<ITokenState> {
  constructor() {
    super(createInitialState());
  }
}
