<section class="container" *ngIf="showQuantityProgress || sources?.length > 0 || showNoDataAvailable">
  <div class="completed">
    <h2 class="title">
      Completed
      <span *ngIf="showQuantityProgress">
        {{quantityCompleted}} of {{quantityRequired}}
      </span>
    </h2>

    <div class="detail-source">
      <div *ngFor="let source of sources" class="card">
        <h3 class="card-title">
          {{ source.sys }}
        </h3>

        <span class="date">
          {{ source.tDate | date:'mediumDate'}}
        </span>
        <br>
        <span class=" card-title card-span">
          {{ source.tId }}
        </span>
      </div>

      <p *ngIf="showNoDataAvailable">
        No data available.
      </p>
    </div>
  </div>
</section>
