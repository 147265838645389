import { Point } from './../models/competency-element.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Configuration } from '~/shared/models/configuration.model';
import { Observable } from 'rxjs/internal/Observable';
import { CompetencyApiBaseService } from './base/competency-api-base.service';
import { PromotionDto } from './dtos/promotion.dto';
import { Promotion } from '../models/promotion.model';
import { PromotionStore } from '~/store/promotion/promotion.store';
import { AuthService } from '~/core/services/auth.service';
import { map } from 'rxjs/internal/operators/map';
import { TokenQuery } from '~/store/token/token.query';
import { tap, timeout, retry } from 'rxjs/operators';
import { PromotionGroupDto } from './dtos/promotion-group-dto';
import { PromotionGroup } from '../models/promotion-group.model';
import { PromotionRequirementDto } from './dtos/promotion-requirement-dto';
import { PromotionRequirement } from '../models/promotion-requirement.model';
import { PromotionCompetencyElement } from '../models/competency-element.model';
import { PromotionCompetencyElementDto, PointDto } from './dtos/promotion-competency-element-dto';
import { ProficiencyLevelType } from '../enums/proficiency-level-type.enum';

@Injectable({ providedIn: 'root' })
export class PromotionService extends CompetencyApiBaseService<Promotion> {
  private groupNameParam = '';

  constructor(
      httpClient: HttpClient,
      config: Configuration,
      tokenQuery: TokenQuery,
      private promotionStore: PromotionStore,
      private authService: AuthService
  ) {
      super(httpClient, config, tokenQuery);
      this.endpoint = 'getfsp';
  }

  public getByGinNumber(ginNumber: number): Observable<Promotion> {
      return this.getPatSingleCustom(`${this.config.apiUrlAdminTool}/${this.endpoint}?gin=${ginNumber}`, true)
          .pipe(
            tap((dto: any) => {
              if (dto) {
                  const promotion = this.mapPromotionDtoToPromotion(dto as PromotionDto);
                  this.promotionStore.update({ promotion });
                  return promotion;
              }
              return null;
            }),
            timeout(30000),
            retry(1)
          );
  }

  private mapPromotionDtoToPromotion(dto: PromotionDto) {
    const promotion = new Promotion({
      gin: dto.gin,
      promotionResult: dto.fsp_res,
      requesterGin: dto.requester_gin,
      trigger: dto.trigger,
      stepId: dto.step_id,
      stepName: dto.step_name,
      jobCode: dto.jobcode,
      grade: dto.grade,
      businessCode: dto.bcode,
      groupingType: dto.step_grouping_type,
      promotionGroups: this.mapPromotionGroupDtoToPromotionGroup(dto.grps)
    });

    return promotion;
  }

  private mapPromotionGroupDtoToPromotionGroup(dtos: Array<PromotionGroupDto>): Array<PromotionGroup> {
    const groupPromotions = dtos.map(pgd => {
      return new PromotionGroup({
        promotionGroupId: pgd.promo_grp_id,
        name: pgd.promo_grp_name,
        groupingType: pgd.promo_grp_type,
        groupResult: pgd.promo_grp_result,
        requirements: this.mapPromotionRequirementDtoToPromotionRequirement(pgd.rqrmnts)
      })
    });

    return groupPromotions;
  }

  private mapPromotionRequirementDtoToPromotionRequirement(dtos: Array<PromotionRequirementDto>): Array<PromotionRequirement> {
    const promotionRequirements = dtos.map(prd => {
      return new PromotionRequirement({
        promotionRequirementId: prd.promo_reqrmnt_id,
        requirement: prd.promo_reqrmnt_name,
        requirementType: prd.promo_reqrmnt_type,
        requirementResult: prd.promo_reqrmnt_result,
        requiredCount: prd.promo_reqrmnt_reqrd_ce_count,
        requiredPoints: prd.promo_reqrmnt_points_needed,
        requiredMaximum: prd.promo_reqrmnt_max_points,
        pointsAchieved: prd.promo_reqrmnt_points_achieved,
        cecount: prd.promo_reqrmnt_ce_count,
        requiredLevel:  prd.promo_reqrmnt_prof_lvl,
        achievedCeCount: prd.promo_reqrmnt_achieved_ce_count,
        competencyElements: this.mapPromotionCompetencyElementDtoToPromotionCompetencyElement(prd.ces)
      })
    });

    return promotionRequirements;
  }

  private mapPromotionCompetencyElementDtoToPromotionCompetencyElement(dtos: Array<PromotionCompetencyElementDto>): Array<PromotionCompetencyElement> {
    const promotionCompetencyElements = dtos.map(pce => {
      return new PromotionCompetencyElement({
        ceId: pce.ce_id,
        name: pce.ce_name,
        competencyUnit: pce.cu_name,
        ceResult: pce.ce_result,
        employeeProficiencyLevel: pce.employee_prof_lvl,
        requiredProficiencyLevel: pce.rqrd_prof_lvl,
        awardedOnDate: pce.awarded_on_date,
        points: this.mapRequirementPoints(pce.points)
      })
    });

    return promotionCompetencyElements;
  }

  private mapRequirementPoints(dtos: Array<PointDto>): Array<Point> {
    const points = dtos?.map(point => {
      return new Point({
        proficiencyLevelType: point.prof_lvl as ProficiencyLevelType,
        cePoints: point.ce_point
      })
    }) ?? [];

    return points;
  }

  public get getGroupNameParam() : string {
    return this.groupNameParam;
  }

  public set setGroupNameParam(groupNameParam : string) {
    this.groupNameParam = groupNameParam;
  }
}
