import { CompetencyTicketService } from './../../../../shared/services/competency-ticket.service';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Configuration } from '~/shared/models/configuration.model';
import { feedbackData, IFeedbackData, IFeedbackField, IFeedbackType } from '~/configs/feedback-config';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CompetencyTicket, CompetencyTicketResponse } from '~/shared/models/competency-ticket.model';

const regexSegment = new RegExp(/<!\[CDATA\[SPS-Schlumberger Performance System]]><\/SEGMENTNAME><SEGMENTID><!\[CDATA\[(\d+)(?=\]\])/);
const regexHelpDesk = new RegExp(/(<HELPDESKNAME><!\[CDATA\[SPS-JE-COMPETENCY\]\]><\/HELPDESKNAME><HELPDESKID><!\[CDATA\[\d+\]\]><\/HELPDESKID><CATEGORYID><!\[CDATA\[)(\d+)(\]\])/);
const regexTicket = RegExp(/(<TICKETID><!\[CDATA\[)(\d+)(\]\])/);
const summaryPrefix = '[MyPCP]:';
const sSourceSystemName = 'Competency';
const nPriorityLevel = 71;
const summaryFields: Array<string> = ['subtype', 'whatsyourproblem'];

@Component({
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {

  get feedbackItem(): IFeedbackType | null {
    if (!this.form.value.type) return null;
    return this.feedback.types.find((i) => i === this.form.value.type);
  }

  get hasTitle(): boolean {
    if (!this.feedbackItem) return false;
    return this.feedbackItem.hasTitle;
  }

  get getFields(): Array<IFeedbackField> {
    if (!this.feedbackItem) return [];
    return this.feedback.fields.filter((i) => this.feedbackItem.fields.includes(i.id))
  }

  public feedback: IFeedbackData = feedbackData;
  public form: FormGroup;
  public feedbackSent = false;
  public isLoading = false;
  public ticketNo = '';
  public inTouchUrl = '';
  public businessLineId = '0';
  public helpdeskId = '0';
  public ldap = '';
  public urlEsm = '';

  public competencyElementName: string;
  public error = false;

  public constructor(
    private config: Configuration,
    private competencyTicketService: CompetencyTicketService,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar
  ) {
    this.form = new FormGroup({
      type: new FormControl(null, [Validators.required]),
      title: new FormControl(null),
      whereisyourproblem: new FormControl(null),
      whatsyourproblem: new FormControl(null),
      subtype: new FormControl(null),
      description: new FormControl(null),
      descriptionmandatory: new FormControl(null)
    });
  }

  public ngOnInit(): void {
    this.verifyEnvironmentUrlEsm();
    if (this.hasParameters()) {
      this.form.controls.type.setValue(
          this.feedback.types.find((x) => x.description === 'Report a problem with data')
      );

      this.form.controls.whereisyourproblem.setValue(
        this.whereIsProblemStartReportProblemOnData()
      );

      this.form.controls.description.setValue(
        this.descriptionStartReportProblemOnData()
      );
    }
  }

  private getSummary(): string {
    let summary = `${summaryPrefix}Type:${this.feedbackItem.description}`;

    if (this.feedbackItem.hasTitle)
      summary += `-title:${this.form.value.title}`;
    const idSummary = this.feedbackItem.fields.find((field) => summaryFields.includes(field));
    const itemSummaryDescription = this.feedback.fields.find((field) => field.id === idSummary);
    if (idSummary && this.form.value[idSummary])
      summary += `-${itemSummaryDescription.description}:${this.form.value[idSummary]}`;

    return summary;
  }

  private getDescription(): string {
    let details = '';

    for (const i of this.getFields.filter((field) => !(summaryFields.includes(field.id)))) {
      if (this.form.value[i.id])
        details += `${i.description}:${this.form.value[i.id]}`;
    }

    return details
  }

  private validation() {
    for (const i of this.getFields) {
      if (i.mandatory && !this.form.value[i.id]) {
        this.form.controls[i.id].setErrors({ incorrect: true });
      }
    }
    if (this.feedbackItem.hasTitle && !this.form.value.title) {
      this.form.controls.title.setErrors({ incorrect: true });
    }
  }

  public hasParameters(): boolean {
    if (this.data?.competencyelementname)
      return true;
    else return false
  }

  public close(): void {
    this.dialogRef.close();
  }

  public openSnackBar(message: string) {
    this.snackBar.open(message);
  }

  public onError(error: Error) {
    this.close();
    this.openSnackBar(error.message);
  }

  public submitFeedback(): void {
    this.error = false;

    this.validation();
    if (this.form.invalid) {
      this.error = true;
      return;
    }

    const summary = this.getSummary();
    if (this.error) {
      return;
    }

    const details = this.getDescription();

    this.isLoading = true;
    const competencyTicket = new CompetencyTicket({
      serviceOffering: this.competencyTicketService.getserviceOffering,
      description: details,
      shortDescription: summary
    });

    this.competencyTicketService.createCompetencyTicket(competencyTicket).subscribe((response: CompetencyTicketResponse) => {
      if (!response) {
        this.sendCreatingError();
      }

      if (response.msg && response.msg !== '') {
        this.ticketNo = response.msg;
        this.form.reset();
        this.feedbackSent = true;
      } else {
        this.sendCreatingError();
      }
    });
  }

  sendCreatingError () {
    this.onError(new Error(('Error creating feedback')));
  }

  whereIsProblemStartReportProblemOnData(): string {
    const message = `${this.data?.competencyelementname}\nUser Gin: ${this.data.gin}`;

    return message;
  }

  descriptionStartReportProblemOnData(): string {
    const gin = this.data.gin ? this.data.gin : '0';
    const ceId = this.data.ceId ? this.data.ceId : '0';
    const yourDate = new Date().toISOString().split('T')[0];
    let filter = `?filter=DIM_x0020_People/GIN eq ${gin} and DIM_x0020_Competency_x0020_Element/CeID eq ${ceId}`;
    let dateFilter =  ` and FACT_x0020_PCPSource/TransactionDateTime ge ${yourDate}`;
    const rgxExpAllSpaces = / /g;
    filter = filter.replace(rgxExpAllSpaces, '%20');
    dateFilter = dateFilter.replace(rgxExpAllSpaces, '%20');

    const reportLinks = [
      `Report link A "PCP Summary" - https://app.powerbi.com/groups/me/apps/813b3d0c-9800-4126-b769-245e3596a7d7/reports/21eeea94-939b-4bec-aabf-c13fb5da82e6/ReportSection${filter}`,
      `Report link B "PCP Requirements" - https://app.powerbi.com/groups/me/apps/813b3d0c-9800-4126-b769-245e3596a7d7/reports/21eeea94-939b-4bec-aabf-c13fb5da82e6/ReportSection15802199205e1d638e5b${filter}`,
      `Report link C "PCP Source Data" - https://app.powerbi.com/groups/me/apps/813b3d0c-9800-4126-b769-245e3596a7d7/reports/21eeea94-939b-4bec-aabf-c13fb5da82e6/ReportSection4e50bb1a0344b443902a${filter}${dateFilter}`
    ]

    const message = `${reportLinks.join('\n\n')}`;

    return message;
  }

  verifyEnvironmentUrlEsm() {
    this.urlEsm = this.competencyTicketService.getLinkEsmFeedback;
  }
}
