import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IsAuthenticatedGuard } from '~/core/guards/is-authenticated.guard';
import { IsOnlineGuard } from '~/core/guards/is-online.guard';
import { CompetencyElementsComponent } from './pages/competency-elements/competency-elements.component';
import { CompetencyUnitsComponent } from './pages/competency-units/competency-units.component';
import { MyElementsComponent } from './pages/my-elements/my-elements.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [IsAuthenticatedGuard, IsOnlineGuard],
    component: CompetencyUnitsComponent
  },
  {
    path: ':competency-unit/elements',
    canActivate: [IsAuthenticatedGuard, IsOnlineGuard],
    component: CompetencyElementsComponent,
    data:
    {
      showBackButton: true,
      showHomeButton: true,
      showFooterMenu: false
    }
  },
  {
    path: ':proficiency-level/:competency-unit/elements',
    canActivate: [IsAuthenticatedGuard, IsOnlineGuard],
    component: CompetencyElementsComponent,
    data:
    {
      showBackButton: true,
      showHomeButton: true,
      showFooterMenu: false
    }
  },
  {
    path: ':competency-unit/elements/:competency-element/assessments',
    canActivate: [IsAuthenticatedGuard, IsOnlineGuard],
    data:
    {
      showBackButton: true,
      showHomeButton: true,
      showFooterMenu: false
    }
  },
  {
    path: 'my-elements',
    canActivate: [IsAuthenticatedGuard, IsOnlineGuard],
    component: MyElementsComponent,
    data:
    {
      showBackButton: true,
      showHomeButton: true,
      showFooterMenu: false
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CompetencyUnitsRoutingModule { }
