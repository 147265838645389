export class ErrorResponse {
  error: ErrorResponseData;

  constructor(init?: Partial<ErrorResponse>, dto?: ErrorResponseDto) {
    if (dto) {
      this.error = new ErrorResponseData({}, dto.Error);
    }
    else {
      Object.assign(this, init);
    }
  }
}

export class ErrorResponseData {
  errorCode: string;
  message: string;
  target: string;
  details: any;
  instanceAnnotations: any;
  innerError: any;
  typeAnnotation: any;

  constructor(init?: Partial<ErrorResponseData>, dto?: ErrorResponseDataDto) {
    if (dto) {
      this.errorCode = dto.ErrorCode;
      this.message = dto.Message;
      this.target = dto.Target;
      this.details = dto.Details;
      this.instanceAnnotations = dto.InstanceAnnotations;
      this.innerError = dto.InnerError;
      this.typeAnnotation = dto.TypeAnnotation;
    }
    else {
      Object.assign(this, init);
    }
  }
}

export class ErrorResponseDto {
  Error: ErrorResponseDataDto;
}

export class ErrorResponseDataDto {
  ErrorCode: string;
  Message: string;
  Target: string;
  Details: any;
  InnerError: any;
  InstanceAnnotations: any;
  TypeAnnotation: any;
}
