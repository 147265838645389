import { AssessmentDetails } from "./assessment-details.model"

export class CompetencyDetails {
    public items:Array<Items>
    public pageIndex:number
    public totalPages:number
    public totalCount:number
    public hasPreviusPage:boolean
    public hasNextPage:boolean
}

export class Items {
    public competencyElementId:number
    public certificationId:string
    public certificationName:string
    public certificationLink:string
    public ple:boolean
    public pl:string
    public pathway:string
}

export class Assessor {
  ceId: number;
  email: string;
  gin: number;
  job: string;
  name: string;
  pl: number;
  unit: string;
  isSelected: boolean;
}

export class Training {
    EmployeeGin: number;
    competencyElementId: number;
    pathwayName: string;
    proficiencyLevel: string;
    completedGroups: number;
    totalGroups: number;
    isCompleted: boolean;
    completionDate: string;
    firstGroupCompletionDate: string;
    groups: Array<any>;
  }

export class ElementDetails {
    public sources:Array<any>;
    public certs:Array<any>;
    public assessors:Array<Assessor>
    public training: Training;
    public assessmentDetails: AssessmentDetails;

    constructor(data?: Partial<ElementDetails>) {
        Object.assign(this, data);
    }
}
