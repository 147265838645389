import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoaderService {

  private _loaderStatus = new Subject<any>();
  private count = 0;
  private isVisible = false;
  private isDisabled = false;
  public $loaderStatus: Observable<any>;

  constructor() {
    this.$loaderStatus = this._loaderStatus.asObservable();
  }

  public enqueue() {
    if (this.isDisabled) {
      return;
    }

    this.count++;
    if (!this.isVisible) {
      this.isVisible = true;

      this._loaderStatus.next({
        visible: true
      });
    }
  }

  public dequeue() {
    if (this.isDisabled) {
      return;
    }

    if((--this.count) < 0) {
      this.count = 0;
    }

    if (this.count === 0) {
      this.isVisible = false;

      this._loaderStatus.next({
        visible: false
      });
    }
  }

  public disable() {
    this.isDisabled = true;
  }

  public enable() {
    this.isDisabled = false;
  }

  public clear() {
    this.count = 0;
    this.isVisible = false;

    this._loaderStatus.next({
      visible: false
    });
  }

  public isLoading() {
    return this.count > 0;
  }
}
