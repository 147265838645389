import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FeedbackComponent } from '~/modules/feedback/pages/feedback/feedback.component';
import { isMobileSize, MIN_WIDTH_DIALOG_LG, MIN_WIDTH_DIALOG_MOBILE } from '~/shared/utils/navigator';
import {Location} from '@angular/common'
import { Router } from '@angular/router';

@Component({
  selector: 'pcp-feedback-button',
  templateUrl: './feedback-button.component.html',
  styleUrls: ['./feedback-button.component.scss']
})
export class FeedbackButtonComponent  implements OnInit {

  constructor(public dialog: MatDialog, private location : Location , private router : Router) {
    
  }
  getURL : string =""
  reDirectURL : string=""
  ngOnInit(): void {
     this.getURL  = this.location.path();
     if( this.getURL.includes('http://localhost:4200/#/home')||this.getURL.includes('mypcp-dev.azurewebsites.net')|| this.getURL.includes('mypcp-qa.azurewebsites.net') ){
       this.reDirectURL = "https://slbstaging.service-now.com/esc?id=sc_cat_item&table=sc_cat_item&sys_id=60e3eb0d1b29b910e599dac7bd4bcb25"
     }else{
      this.reDirectURL = "https://slb.service-now.com/esc?id=sc_cat_item&table=sc_cat_item&sys_id=60e3eb0d1b29b910e599dac7bd4bcb25"
     }
  }
  
  url(event : Event):void{
    
   event.preventDefault();
    window.open(this.reDirectURL, "_blank")
  }
  public onClickButton() {
    const sizeDialog: MatDialogConfig = {
      minWidth: MIN_WIDTH_DIALOG_MOBILE,
    };

    if (isMobileSize())
      sizeDialog.minWidth = MIN_WIDTH_DIALOG_LG

    this.dialog.open(FeedbackComponent, sizeDialog)
  }

}
