export class Configuration {
  //public apiUrl: string;    //de-commisioned
  public newApiUrl: string;
  public apiUrlEsm: string;
  public graphApi: string;
  public apiKey: string;
  public azureTenantId: string;
  public azureClientId: string;
  public instrumentationKey: string;
  public version: string;
  public apiScope: string;
  public isUnderMaintenance: string;
  public newApiKey: string;
  public casApiKey: string;
  public ussApiKey: string;
  public casApiUrl: string;
  public ussApiUrl: string;
  public casApiScope: string;
  public ussApiScope: string;
  public azureImpersonationGroupId: string;

  // CAT API
  public catApiScope: string;
  public catApiKey: string;
  public catApiUrl: string;

  // CR1497
  public ilearnUrlPrefix: string;
  // PBI-KAIZEN:1776090

  // Esm-Ticket
  public esmApiScope: string;
  public esmServiceOffering: string;
  public linkEsmFeedback: string;

  public apiUrlAdminTool:string;
  public env: string;

  public urlCreateTicketiLearn: string;
}
