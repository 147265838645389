import { Injectable } from '@angular/core';
import { CompetencyApiBaseService } from './base/competency-api-base.service';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../models/configuration.model';
import { TokenQuery } from '~/store/token/token.query';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AssessmentDto } from './dtos/assessment.dto';
import { ErrorResponse } from './dtos/error.dto';
import { ResponseItemDto } from './dtos/response-item.dto';
import { ResponseItemService } from './response-item.service';
import { AssessmentStatus } from '../enums/assessment-status.enum';
import { MainDivisionDto } from './dtos/main-division.dto';

@Injectable({
  providedIn: 'root'
})
export class AssessmentService extends CompetencyApiBaseService<any> {

  constructor(
    httpClient: HttpClient,
    config: Configuration,
    token: TokenQuery,
    private responseItemService: ResponseItemService<AssessmentDto | ErrorResponse>
  ) {
    super(httpClient, config, token);
  }

  public addAssessment(assessorAssessment: AssessmentDto): Observable<ResponseItemDto<AssessmentDto | ErrorResponse>> {
    const result = new Subject<ResponseItemDto<AssessmentDto | ErrorResponse>>();

    this.postSingleCAS(`${this.config.casApiUrl}/cas/assessment/add`, assessorAssessment)
      .pipe(
        catchError((err) => {
          result.next(undefined);
          return throwError(err)
        }),
        tap((dto: AssessmentDto | ErrorResponse) => {
          if (dto && (dto as AssessmentDto).id) {
            result.next(this.responseItemService.createSuccessResponseItem<AssessmentDto>((dto as AssessmentDto)));
          } else {
            result.next(this.responseItemService.createErrorResponseItem<ErrorResponse>((dto as ErrorResponse)));
          }
        })
    ).subscribe();

    return result;
  }

  public getAssessment(assesseeGin: number, competenyElementId: number, proficiencyLevel: string) {
    const result = new Subject<AssessmentDto | ErrorResponse | any>();

    this.getSingleCAS(`${this.config.casApiUrl}/cas/assessment/get/filter?AssesseeGin=${assesseeGin}&CompetencyElementId=${competenyElementId}&ProficiencyLevel=${proficiencyLevel}`)
      .pipe(
        catchError((err) => {
          result.next(undefined);
          return throwError(err)
        }),
        tap((dto: AssessmentDto | ErrorResponse | any) => {
          if (dto && !dto.error) {
            if(dto.status || dto.status === 0) {
              dto.status = this.mapAssessmentStatus(dto.status);
            }

            return result.next(dto);
          } else {
            result.next(null);
          }
        })
    ).subscribe();

    return result;
  }

  public getCatAssessment(competenyElementId: number): Observable<Array<MainDivisionDto>> {
    const result = new Subject<Array<MainDivisionDto>>();

    this.getSingleCAT(`${this.config.catApiUrl}/cpa/groups/get?CompetencyElementId=${competenyElementId}`)
      .pipe(
        catchError((err) => {
          result.next(undefined);
          return throwError(err)
        }),
        tap((dto: Array<MainDivisionDto> | ErrorResponse) => {
          if (dto && (dto as Array<MainDivisionDto>).length > 0) {
            result.next((dto as Array<MainDivisionDto>));
          } else {
            result.next(null);
          }
        })
    ).subscribe();

    return result;
  }

  public removeAssessment(assessmentId: number): Observable<ResponseItemDto<any | ErrorResponse>> {
    const result = new Subject<ResponseItemDto<AssessmentDto | ErrorResponse>>();

    this.postSingleCAS(`${this.config.casApiUrl}/cas/assessment/remove?AssessmentId=${assessmentId}`, {})
      .pipe(
        catchError((err) => {
          result.next(undefined);
          return throwError(err)
        }),
        tap((response: any | ErrorResponse) => {
          if (response === true && !response?.error) {
            result.next(this.responseItemService.createSuccessResponseItem<any>((response as any)));
          } else {
            result.next(this.responseItemService.createErrorResponseItem<ErrorResponse>((response as ErrorResponse)));
          }
        })
    ).subscribe();

    return result;
  }

  private mapAssessmentStatus(value: any): string {
    if(value >= 0) {
      return AssessmentStatus[value];
    }

    return null;
  }
}
