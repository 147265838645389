import { TokenQuery } from './../../../store/token/token.query';
import { HttpClient, HttpHeaders, HttpXhrBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { LocalStorageKeys } from '~/shared/constants/local-storage-keys';
import { Configuration } from '~/shared/models/configuration.model';

const helper = new JwtHelperService();

@Injectable()
export abstract class CompetencyApiBaseService<T> {
  protected endpoint = '';
  public token: string;
  public headers: HttpHeaders;
  public headersNew: HttpHeaders;
  public headersEsm: HttpHeaders;
  public headersCAS: HttpHeaders;
  public headersCAT: HttpHeaders;
  public headersUSS: HttpHeaders;

  constructor(
    protected httpClient: HttpClient,
    protected config: Configuration,
    protected tokenQuery: TokenQuery
  ) {
    this.tokenQuery.token$.subscribe((token) => {
      this.token = token;
      this.headers = new HttpHeaders()
        .set('Authorization', `Bearer z`)
        .set('x-apikey', this.config.apiKey);
    });

    this.tokenQuery.msalApiToken$.subscribe((token) => {
      this.token = token;
      this.headersNew = new HttpHeaders()
        .set('Authorization', `Bearer ${this.token}`)
        .set('x-apikey', this.config.newApiKey);
    });

    this.tokenQuery.esmApiToken$.subscribe((token) => {
      this.token = token;
      this.headersEsm = new HttpHeaders()
        .set('Authorization', `Bearer ${this.token}`)
        .set('x-apikey', this.config.newApiKey);
    });

    this.tokenQuery.casApiToken$.subscribe((token) => {
      this.token = token;
      this.headersCAS = new HttpHeaders()
        .set('Authorization', `Bearer ${this.token}`)
        .set('x-apikey', this.config.casApiKey);
    });

    this.tokenQuery.ussApiToken$.subscribe((token) => {
      this.token = token;
      this.headersUSS = new HttpHeaders()
        .set('Authorization', `Bearer ${this.token}`)
        .set('x-apikey', this.config.ussApiKey)
        .set('x-content-type-options', 'nosniff')
        .set('expires', '0')
        .set('x-frame-options', 'SAMEORIGIN')
        .set('content-security-policy', "default-src 'none';frame-src 'self' ;connect-src 'self'; script-src 'self' http://az416426.vo.msecnd.net/scripts/a/ai.0.js 'unsafe-inline' 'unsafe-eval' ;img-src 'self';font-src 'self';style-src 'self' 'unsafe-inline';")
        .set('strict-transport-security', "max-age=31536000; includeSubDomains")
    });

    this.tokenQuery.catApiToken$.subscribe((token) => {
      this.token = token;
      this.headersCAT = new HttpHeaders()
        .set('Authorization', `Bearer ${this.token}`)
        .set('x-apikey', this.config.catApiKey);
    });
  }

  protected encode(value: string): string {
    return encodeURIComponent(value);
  }

  protected get(url: string): Observable<Array<T>> {
    return this.internalGet(url);
  }

  protected getCustom(url: string): Observable<Array<T>> {
    return this.internalGetCustom(url);
  }

  protected getSingle(url: string): Observable<T> {
    return this.internalGetSingle(url);
  }

  protected getSingleCustom(url: string): Observable<T> {
    return this.internalGetSingleCustom(url);
  }

  protected getPatSingleCustom(url:string, envHeader?: boolean):Observable<T>{
    return this.internalGetPat(url, envHeader);
  }

  protected postSingleEsmApi(url: string, body: any, envHeader?: boolean): Observable<T> {
    return this.internalPostEsmApi(url, body, envHeader);
  }

  protected getSingleCAS(url: string): Observable<T> {
    return this.internalGetCAS(url);
  }

  protected getUSSingleCustom(url:string):Observable<T>{
    return this.internalGetUSS(url);
  }

  protected getSingleCAT(url: string): Observable<T> {
    return this.internalGetCAT(url);
  }

  protected removeSingleCAS(url: string): Observable<T> {
    return this.internalDeleteCAS(url);
  }

  protected postSingleCAS(url: string, body: any): Observable<T> {
    return this.internalPostCAS(url, body);
  }

  private internalGetUSS(url: string): Observable<T> {
    return this.httpClient.get<T>(url, { headers: this.headersUSS });
  }

  private internalGetPat(url: string, envHeader?: boolean): Observable<T>{
    if (this.config.env === 'dev') {
      this.headersNew = this.headersNew.set('x-slb-env', this.config.env);
    }

    return  this.httpClient.get<T>(url, { headers: this.headersNew });
  }

  private internalGet(url: string): Observable<Array<T>> {
    return this.httpClient.get<Array<T>>(url, { headers: this.getDefaultHeaders() });
  }

  private internalGetSingle(url: string): Observable<T> {
    return this.httpClient.get<T>(url, { headers: this.getDefaultHeaders() });
  }

  private internalGetSingleCustom(url: string): Observable<T> {
    return this.httpClient.get<T>(url, { headers: this.headers });
  }

  private internalGetCustom(url: string): Observable<Array<T>> {
    return this.httpClient.get<Array<T>>(url, { headers: this.headers });
  }

  private internalPostEsmApi(url: string, body: any, envHeader?: boolean): Observable<T> {
    if (envHeader === true && this.config.env === 'dev') {
      this.headersEsm = this.headersEsm.set('x-slb-env', this.config.env);
    }

    return this.httpClient.post<T>(url, body, { headers: this.headersEsm });
  }

  private internalGetCAS(url: string): Observable<T> {
    return this.httpClient.get<T>(url, { headers: this.headersCAS });
  }

  private internalGetCAT(url: string): Observable<T> {
    return this.httpClient.get<T>(url, { headers: this.headersCAT });
  }

  private internalDeleteCAS(url: string): Observable<T> {
    return this.httpClient.delete<T>(url, { headers: this.headersCAS });
  }

  private internalPostCAS(url: string, body: any): Observable<T> {
    return this.httpClient.post<T>(url, body, { headers: this.headersCAS });
  }

  private getDefaultHeaders(): any {
    return {
      'x-apikey': this.config.apiKey
    }
  }
}
