import { AfterViewInit, Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { PageComponentBase } from '../../../../shared/components/page-component-base';
import { PullToRefreshService } from '~/core/services/pull-to-refresh.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Promotion } from '~/shared/models/promotion.model';
import { PromotionGroup } from '~/shared/models/promotion-group.model';
import { PromotionQuery } from '~/store/promotion/promotion.query';
import { getClassProgress } from '~/shared/utils/class-progress';
import { PromotionService } from '~/shared/services/promotion.service';

@Component({
  selector: 'pcp-promotion',
  templateUrl: './promotion.component.html',
  styleUrls: ['./promotion.component.scss']
})
export class PromotionComponent extends PageComponentBase implements OnInit, AfterViewInit {

  @Input() public isHome = false;
  public panelOpenState: boolean;
  public promotion$: Promotion;
  public groupParam: string;
  public stateOfPanel = false;

  constructor(
    pullToRefreshService: PullToRefreshService,
    private router: Router,
    private route: ActivatedRoute,
    private promotionQuery: PromotionQuery,
    private promotionService: PromotionService
  ) {
    super(pullToRefreshService);
  }

  public ngOnInit(): void {
    this.loadData();
  }

  public ngAfterViewInit(): void {
    this.groupParam = this.promotionService.getGroupNameParam;
    if (this.groupParam !== undefined && this.groupParam !== '') {
      const matExpansions = document.querySelectorAll('mat-accordion mat-expansion-panel');
      if (matExpansions !== undefined) {
        for (const i in matExpansions) {
          if (matExpansions[i].textContent && matExpansions[i].textContent.includes(this.groupParam)) {
            this.stateOfPanel = true;
          }
        }
      }
    }
  }

  public refreshRequested() {
    this.finishLoading();
  }

  public viewGroup(group: PromotionGroup): void {
    if (this.stateOfPanel === false) {
      this.promotionService.setGroupNameParam = group.name;
    } else {
      this.groupParam = group.name;
    }

    this.router.navigate(['promotion'],
      {
        state: { title: group.name }
      });
  }

  public loadData(): void {
    this.promotionQuery.select().subscribe((state) => {
      if (state && state.promotion) {
        this.promotion$ = state.promotion;
      }
    });
  }

  public navigateToPromotion() {
    this.router.navigate(['promotion']);
  }

  public getClassProgressByValue(value: number): string {
    return getClassProgress(value)
  }

  public isPanelOpen(group: string){
    if(group === this.route.snapshot.queryParams.group) return true;
    return false
  }

  anyPromotionGroup(): boolean {
    return this.promotion$ !== undefined && this.promotion$.promotionGroups !== undefined && this.promotion$.promotionGroups.length > 0;
  }
}
