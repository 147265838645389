import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, Subscription } from 'rxjs';
import { MatchFilterType } from '~/core/enums/match-filter-type.enum';
import { Filter, FilterSelect } from '~/shared/models/filter.model';

const MILLISECONDS_DEBOUNCE = 400;
@Component({
  selector: 'app-generic-filter',
  templateUrl: './generic-filter.component.html',
  styleUrls: ['./generic-filter.component.scss']
})
export class GenericFilterComponent implements OnInit {
  @Input() public showFilterSearch = true;
  @Input() public showFilterSelection = true;
  @Input() public labelsToRemove: Array<string> = [];
  @Input() public fnFilterAdditional = () => { return true; };
  @Input() public fnFilterOptions = () => { return false; };
  @Input() public filtersDefault: { key, value }[] = [];
  @Input() public dataSource: Array<any> = [];
  @Input() public selectedFilters: Array<any>;
  @Input() public returnSearchToComponent = false;
  @Input() public returnSelectAndSearchToComponent = false;
  @Input() public isFilterApprover = false;
  @Input() public matchFilterType: MatchFilterType = MatchFilterType.union;

  @Output() public filtered = new EventEmitter<any>();
  @Output() public searchingInComponent = new EventEmitter<string>();
  @Output() public selectingInComponent = new EventEmitter<any>();

  public labels: any[] = [];
  public labelKeys: any[] = [];
  public search = new FormControl();
  public filters: Filter = { search: '', selects: [] };
  private searchSubscription: Subscription;
  private filteredDataSource: Array<any> = [];

  ngOnInit() {
    if (this.labelsToRemove) {
      this.labelKeys = this.labelKeys.filter(i => !this.labelsToRemove.includes(i));
    }

    if (this.filteredDataSource?.length === 0) {
      this.filteredDataSource = this.dataSource;
    }

    this.searchSubscription = this.search.valueChanges
      .pipe(
        debounceTime(MILLISECONDS_DEBOUNCE),
        distinctUntilChanged()
      )
      .subscribe(() => {
        const fromSearch = true;
        this.applyFilters(fromSearch);
      });
  }

  ngOnChanges() {
    if (this.filteredDataSource?.length === 0) {
      this.filteredDataSource = this.dataSource;
    }

    if (this.filtersDefault?.length > 0) {
      this.filtersDefault.forEach((i) => {
        const indexFilter = this.filters.selects.findIndex((f) => f.key === i.key);
        if (indexFilter >= 0) {
          this.filters.selects.splice(indexFilter, 1);
        }
        this.keyValueSelected(i);
      });
    }
  }

  removeSelectedFilter(filterSelect: FilterSelect): void {
    const index = this.filters.selects.indexOf(this.filters.selects.find(f => f.key === filterSelect.key && f.value === filterSelect.value));
    this.filters.selects.splice(index, 1);
    this.applyFilters();
  }

  public keyValueSelected(element: FilterSelect): void {
    if (!this.filters.selects.some((f) => f.key === element?.key && f.value === element?.value)) {
      this.filters.selects.push({ key: element?.key, value: element?.value });
    }

    this.applyFilters();
  }

  applyFilters(fromSearch?: boolean): void {

    if (fromSearch && this.returnSearchToComponent) {
      this.searchingInComponent.emit(this.search.value);
      return;
    }

    if (this.returnSelectAndSearchToComponent) {
      this.filters.search = this.search.value;
      this.selectingInComponent.emit(this.filters);
      return;
    }

    if (!this.returnSelectAndSearchToComponent) {
      this.filteredDataSource = this.dataSource.filter(i => {
        return this.matchFilter(i)
          && this.fnFilterAdditional()
          && (!this.search.value
            || Object.values(i).toString().toLowerCase().includes(this.search.value.toLowerCase())
            || Object.values(i).toString().includes(Number(this.search.value).toString())
          );
      });
    }

    this.filtered.emit(this.filteredDataSource);
  }

  matchFilter(item): boolean {
    if (this.filters.selects.length > 0) {
      if (this.matchFilterType === MatchFilterType.union) {
        return this.filters.selects.some(f =>
          this.matchFilterPredicate(item, f)
        );
      } else if (this.matchFilterType === MatchFilterType.intersection) {
        return this.filters.selects.every(f =>
          this.matchFilterPredicate(item, f)
        );
      }
    }
    return true;
  }

  matchFilterPredicate(item, f: FilterSelect) {
    return !Array.isArray(item[f.key]) && item[f.key] === f.value
    || Array.isArray(item[f.key]) && item[f.key].some(x => x === f.value);
  }

  onClickClearSearch(): void {
    this.search.setValue('');
  }

  onSearchFocus(search: HTMLElement) {
    search.focus();
  }

  verifyApproverFilter(){
    return this.returnSelectAndSearchToComponent && this.isFilterApprover;
  }
}
