import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { TokenStore, ITokenState } from './token.store';

@Injectable({ providedIn: 'root' })
export class TokenQuery extends QueryEntity<ITokenState> {

  public token$ = this.select((state) => state.token);
  public msalApiToken$ = this.select((state) => state.msalApiToken);
  public intouchToken$ = this.select((state) => state.intouchToken);
  public esmApiToken$ = this.select((state) => state.esmApiToken);
  public casApiToken$ = this.select((state) => state.casApiToken);
  public catApiToken$ = this.select((state) => state.catApiToken);
  public ussApiToken$ = this.select((state) => state.ussApiToken);

  constructor(protected store: TokenStore) {
    super(store);
  }
}
