import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatPaginatorModule } from '@angular/material/paginator';
import { HeaderComponent } from './components/header/header.component';
import { ListItemComponent } from './components/list-item/list-item.component';
import { ListComponent } from './components/list/list.component';
import { LoaderAnimationComponent } from './components/loader-animation/loader-animation.component';
import { LoaderComponent } from './components/loader/loader.component';
import { MenuComponent } from './components/menu/menu.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { PageComponent } from './components/page/page.component';
import { PullToRefreshComponent } from './components/pull-to-refresh/pull-to-refresh.component';
import { UserHeaderComponent } from './components/user-header/user-header.component';
import { IosPopupAddToHomescreenComponent } from './components/ios-popup-add-to-homescreen/ios-popup-add-to-homescreen.component';
import { ChartComponent } from './components/chart/chart.component';
import { ExpirationsCardsComponent } from './components/expirations-cards/expirations-cards.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { TargetStatusComponent } from './components/target-status/target-status.component';
import { ViewMoreComponent } from './components/view-more/view-more.component';
import { CardItemListComponent } from './components/card-item-list/card-item-list.component';
import { DisplayProficiencyLevelComponent } from './components/display-proficiency-level/display-proficiency-level.component';
import { SelectFilterComponent } from './components/dynamic-filter/components/select-filter/select-filter.component';
import { SelectedFilterComponent } from './components/dynamic-filter/components/selected-filter/selected-filter.component';
import { DynamicFilterComponent } from './components/dynamic-filter/dynamic-filter.component';
import { FeedbackButtonComponent } from './components/feedback-button/feedback-button.component';
import { CardItemListPointsComponent } from './components/card-item-list-points/card-item-list-points.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { HeaderProfileComponent } from './components/header-profile/header-profile.component';
import { LoadingDotsComponent } from './components/loading-dots/loading-dots.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OrderFilterComponent } from './components/dynamic-filter/components/order-filter/order-filter.component';
import { IconDaysAlertComponent } from './components/icon-days-alert/icon-days-alert.component';
import { DialogAssessorsComponent } from './components/assessors/dialog-assessors/dialog-assessors.component';
import { AssessorDetailComponent } from './components/assessors/dialog-assessors/assesor-detail/assessor-detail/assessor-detail.component';
import { HistoryTimelineComponent } from './components/history-timeline/history-timeline.component';
import { YourTrendsComponent } from './components/your-trends/your-trends.component';
import { YourTrendsDialogComponent } from './components/your-trends/your-trends-dialog/your-trends-dialog.component';
import { TrendsNotComplianceDialogComponent } from './components/your-trends/trends-not-compliance-dialog/trends-not-compliance-dialog.component';
import { JobComplianceSummaryComponent } from './components/your-trends/job-compliance-summary/job-compliance-summary.component';
import { SpinnerFeedbackDialogComponent } from './components/spinner-feedback-dialog/spinner-feedback-dialog.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DialogConfirmationComponent } from './components/dialog-confirmation/dialog-confirmation.component';
import { GenericFilterComponent } from './components/generic-filter/generic-filter.component';

const MODULE_MODULES = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  MatProgressBarModule,
  MatSelectModule,
  MatAutocompleteModule,
  MatInputModule,
  MatIconModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatTooltipModule,
  MatTableModule,
  MatPaginatorModule,
  MatProgressSpinnerModule
];

const MODULE_PROVIDERS = [];

const MODULE_COMPONENTS = [
  PageComponent,
  HeaderComponent,
  PageTitleComponent,
  MenuComponent,
  ListComponent,
  ListItemComponent,
  UserHeaderComponent,
  PullToRefreshComponent,
  LoaderComponent,
  LoaderAnimationComponent,
  IosPopupAddToHomescreenComponent,
  ChartComponent,
  ExpirationsCardsComponent,
  UserProfileComponent,
  TargetStatusComponent,
  ViewMoreComponent,
  CardItemListComponent,
  DisplayProficiencyLevelComponent,
  FeedbackButtonComponent,
  CardItemListPointsComponent,
  DynamicFilterComponent,
  SelectedFilterComponent,
  SelectFilterComponent,
  FeedbackButtonComponent,
  HeaderProfileComponent,
  LoadingDotsComponent,
  OrderFilterComponent,
  IconDaysAlertComponent,
  AssessorDetailComponent,
  DialogAssessorsComponent,
  HistoryTimelineComponent,
  YourTrendsComponent,
  YourTrendsDialogComponent,
  TrendsNotComplianceDialogComponent,
  JobComplianceSummaryComponent,
  SpinnerFeedbackDialogComponent,
  DialogConfirmationComponent,
  GenericFilterComponent
];

@NgModule({
  declarations: [
    ...MODULE_COMPONENTS,
  ],
  providers: [
    ...MODULE_PROVIDERS
  ],
  imports: [
    ...MODULE_MODULES
  ],
  exports: [
    ...MODULE_MODULES,
    ...MODULE_COMPONENTS
  ]
})
export class SharedModule { }
