import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TrendQuery } from 'src/app/store/trend/trend.query';
import { User } from 'src/app/shared/models/user.model';
import { Trend } from 'src/app/shared/models/trend.model';
import { UserQuery } from '~/store/user/user.query';

@Component({
  selector: 'your-targets-header',
  templateUrl: './your-targets-header.component.html',
  styleUrls: ['./your-targets-header.component.scss']
})
export class YourTargetsHeaderComponent implements OnInit {

  @Input() public isHome = false;
  public trend$: Trend;
  public user: User;

  constructor(
    private trendQuery: TrendQuery,
    private router: Router,
      private userQuery: UserQuery,
  ) { }

  public ngOnInit(): void {
    this.trendQuery.select().subscribe((state) => {
      this.trend$ = state.trend;
    });

    this.userQuery.select().subscribe((state) => {
      this.user = state.user;
    });
  }

  public setIsHome(value: boolean) {
    this.isHome = value;
  }

  public navigateToHome() {
    this.isHome = true;
    this.router.navigate(['home']);
  }
}
