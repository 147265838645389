import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import * as moment from 'moment-mini-ts';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Directive } from "@angular/core";

@Directive()
export class JsonDateInterceptor implements HttpInterceptor {

  private isoDateFormatRegex = /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}(?:\.\d*)?Z)?$/;

  private isDateString(value: any): boolean {
    if (value === null || value === undefined) {
      return false;
    }

    if (typeof value === 'string') {
      return this.isoDateFormatRegex.test(value);
    }

    return false;
  }

  private convert(body: any) {
    if (body === null || body === undefined) {
      return body;
    }

    if (typeof body !== 'object') {
      return body;
    }

    for (const key of Object.keys(body)) {
      const value = body[key];

      if (this.isDateString(value)) {
        body[key] = moment(value).toDate();

      } else if (typeof value === 'object') {
        this.convert(value);
      }
    }
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(map((val: HttpEvent<any>) => {

      if (val instanceof HttpResponse) {
        const body = val.body;
        this.convert(body);
      }
      return val;
    }));
  }
}
