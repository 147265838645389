import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Configuration } from '../models/configuration.model';
import { TokenQuery } from '~/store/token/token.query';
import { CompetencyApiBaseService } from './base/competency-api-base.service';
import { Observable, Subject, throwError } from 'rxjs';
import { CompetencyDetails, ElementDetails, Training } from '../models/competency-details.model';
import { catchError, retry, tap, timeout } from 'rxjs/operators';
import { ErrorResponse, ErrorResponseDto } from './dtos/error.dto';
import { ResponseItemDto } from './dtos/response-item.dto';
import { ResultResponseType } from '../enums/result-response-type.enum';

@Injectable({ providedIn: 'root' })
export class CompetencyDetailsService extends CompetencyApiBaseService<any> {
  constructor(
    httpClient: HttpClient,
    config: Configuration,
    token: TokenQuery,

  ) {
    super(httpClient, config, token);
    this.endpoint = 'competency_reporting/pcp-details'
  }

  public getDetails(competencyId: number): Observable<CompetencyDetails> {
    return this.getPatSingleCustom(`${this.config.newApiUrl}/${this.endpoint}?CompetencyElementId=${competencyId}`)
      .pipe(tap((dto: CompetencyDetails) => {
        if (dto) {
          return dto;
        }
        return null;
      }));
  }

  public getElementDetails(ginNumber: number, levelId:string, elementId:number, pathway:string, pathwayType:string): Observable<ResponseItemDto<ElementDetails | ErrorResponse>> {
    const subject = new Subject<ResponseItemDto<ElementDetails | ErrorResponse>>();

    this.getPatSingleCustom(`${this.config.newApiUrl}/${this.endpoint}?GinNumber=${ginNumber}&ProficiencyLevelId=${levelId}&CompetencyElementId=${elementId}&Pathway=${pathway}&PathwayType=${pathwayType}`)
      .pipe(
        catchError((error) => {
          return throwError(error);
        }),
        tap((dto: any) => {
          if ('Error' in dto) {
            const errorResponse = new ErrorResponse({}, dto as ErrorResponseDto);
            const responseItemDto = new ResponseItemDto(
              {
                result: ResultResponseType.error,
                error: errorResponse
              }
            );

            subject.next(responseItemDto);
          }
          else {
            const response = dto as ElementDetails;
            const responseItemDto = new ResponseItemDto(
              {
                result: ResultResponseType.success,
                item: response
              }
            );

            subject.next(responseItemDto);
          }

          subject.complete();
      }),
      timeout(10000),
      retry(1)
    ).subscribe();

    return subject;
  }

  public getTrainings(gin: number, competencyId: number, proficiencyLevel: string, pathway: string): Observable<Training> {
    return this.getPatSingleCustom(`${this.config.newApiUrl}/competency_reporting/trainingrequirements?gin=${gin}&pathway=${pathway}&pl=${proficiencyLevel}&ceid=${competencyId}`)
      .pipe(tap((dto: Training) => {
        if (dto) {
          return dto;
        }
        return null;
      }));
  }
}
