<section class="container" [ngClass]="{'is-mobile': checkIfComponentShouldUseMobileSize()}">
    <div class="completed">
      <h2 class="title">
        {{pathwayCompleted ? 'Completed': 'Pending'}}
      </h2>

      <ng-template [ngIf]="showTrainingTemplate()" [ngIfElse]="emptyMessage">
        <div class="detail-source training-detail">
          <p class="subtitle-detail-source">
            {{training.pathwayName}}
          </p>
          <div class="training-group" *ngFor="let item of training?.groups">
            <mat-accordion>
              <mat-expansion-panel [expanded]="true" class="panel-training">
                <mat-expansion-panel-header class="expansion-header">
                  <!-- mobile header -->
                  <mat-panel-title class="panel-header is-mobile" *ngIf="checkIfComponentShouldUseMobileSize()">
                    <div class="training-requirements">
                      <h2 class="title">
                        {{item.name}}
                      </h2>
                      <span class="group-type">
                        {{item.type}}
                      </span>
                      <span class="group-req">
                        Minimum requirements {{item.completedSubGroups}} of {{item.totalSubGroups}}
                      </span>
                    </div>
                  </mat-panel-title>

                  <!-- desktop header -->
                  <mat-panel-title class="panel-header" *ngIf="!checkIfComponentShouldUseMobileSize()">
                      <h2 class="title">
                        {{item.name}}
                      </h2>
                      <br>

                      <span class="group-req">
                        Completed {{item.completedSubGroups}} of {{item.totalSubGroups}}
                      </span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-container>
                  <div class="training-subgroup" *ngFor="let subgroup of item?.subGroups; let idx = index">
                    <mat-accordion *ngIf="subgroup.contents?.length > 0">
                      <mat-expansion-panel [expanded]="true" class="panel-training">
                        <mat-expansion-panel-header class="expansion-header" >
                          <!-- mobile panel -->
                          <mat-panel-title class="panel-header" *ngIf="checkIfComponentShouldUseMobileSize()">
                            <div>
                              <span class="completed-state">
                                <!-- completed icon -->
                                <svg *ngIf="subgroup.isCompleted" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="10" cy="10.5" r="10" fill="#66CC99"/>

                                  <g clip-path="url(#clip0_127_7305)">
                                    <path d="M8.62557 14.8272L4.90918 11.8235L5.57102 11.009L8.47282 13.3508L14.2765 6.17261L15.091 6.83442L8.62557 14.8272Z" fill="white"/>
                                  </g>

                                  <defs>
                                    <clipPath id="clip0_127_7305">
                                      <rect width="11.2" height="11.2" fill="white" transform="translate(4.3999 4.8999)"/>
                                    </clipPath>
                                  </defs>
                                </svg>
                                <!-- pending icon -->
                                <svg *ngIf="!subgroup.isCompleted" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M9.2002 4.30005V10.5L13.3002 15.6L14.4002 14.8L10.6002 10V4.30005H9.2002Z" fill="#6699CC"/>

                                  <path d="M18.6 10C18.5 14.8 14.6 18.6 9.9 18.6C5.2 18.6 1.3 14.7 1.3 10C1.3 5.3 5.2 1.4 9.9 1.4C11.4 1.4 12.9 1.8 14.1 2.5L13.2 3.3L19.1 6.4L16.4 0.4L15.2 1.5C13.7 0.5 11.9 0 10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10H18.6Z" fill="#6699CC"/>
                                </svg>

                                {{subgroup.isCompleted ? 'Completed' : 'Pending'}}
                              </span>

                              <span class="group-req">
                                Minimum requirements {{subgroup.minimum}} of {{subgroup.totalContents}}
                              </span>
                            </div>
                          </mat-panel-title>

                          <!-- desktop panel -->
                          <mat-panel-title class="panel-header" *ngIf="!checkIfComponentShouldUseMobileSize()">
                            <span class="completed-state">
                              <!-- completed icon -->
                              <svg *ngIf="subgroup.isCompleted" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10" cy="10.5" r="10" fill="#66CC99"/>

                                <g clip-path="url(#clip0_127_7305)">
                                  <path d="M8.62557 14.8272L4.90918 11.8235L5.57102 11.009L8.47282 13.3508L14.2765 6.17261L15.091 6.83442L8.62557 14.8272Z" fill="white"/>
                                </g>

                                <defs>
                                  <clipPath id="clip0_127_7305">
                                    <rect width="11.2" height="11.2" fill="white" transform="translate(4.3999 4.8999)"/>
                                  </clipPath>
                                </defs>
                              </svg>
                              <!-- pending icon -->
                              <svg *ngIf="!subgroup.isCompleted" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.2002 4.30005V10.5L13.3002 15.6L14.4002 14.8L10.6002 10V4.30005H9.2002Z" fill="#6699CC"/>

                                <path d="M18.6 10C18.5 14.8 14.6 18.6 9.9 18.6C5.2 18.6 1.3 14.7 1.3 10C1.3 5.3 5.2 1.4 9.9 1.4C11.4 1.4 12.9 1.8 14.1 2.5L13.2 3.3L19.1 6.4L16.4 0.4L15.2 1.5C13.7 0.5 11.9 0 10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10H18.6Z" fill="#6699CC"/>
                              </svg>

                              {{subgroup.isCompleted ? 'Completed' : 'Pending'}}
                            </span>

                            <span class="group-req">
                              Minimum requirements {{subgroup.minimum}} of {{subgroup.totalContents}}
                            </span>
                          </mat-panel-title>

                        </mat-expansion-panel-header>
                        <ng-container *ngIf="subgroup.contents?.length > 0">
                          <div class="training-container" >
                            <a *ngFor="let d of subgroup.contents" 
                              class="detail-source card"
                              href="{{d.url}}" target="_blank">
                              <div class="progress" [class.completed]="d.isCompleted">
                                <span *ngIf="d.isCompleted">
                                  <mat-icon>
                                    done
                                  </mat-icon>
                                  Completed
                                </span>
                                <span *ngIf="!d.isCompleted">
                                  <mat-icon>info</mat-icon>Not Completed
                                  </span>
                              </div>

                              <h3 class="card-title">
                                {{ d.name }}
                              </h3>
                              <span *ngIf="d.isCompleted" class="show-date d-flex flex-column">
                                <span>
                                  {{d.completionDate | date : 'mediumDate'}}
                                </span>
                                <span>
                                  {{d.txId}}
                                </span>
                              </span>
                              <span class="card-span">
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M0 0V14.0001H14.0001V0H0ZM12.9501 12.9501H1.05001V1.05001H12.9501V12.9501Z" fill="#C7D0D8"/>

                                  <path d="M7.07002 3.43016L6.30001 2.66016L4.26999 4.62017L3.49999 3.85017L2.72998 4.62017L4.26999 6.23018L7.07002 3.43016Z" fill="#C7D0D8"/>

                                  <path d="M2.7998 11.3402H6.71984V7.42017H2.7998V11.3402ZM3.84981 8.47017H5.66983V10.2902H3.84981V8.47017Z" fill="#C7D0D8"/>

                                  <path d="M11.2699 3.98999H8.60986V5.04H11.2699V3.98999Z" fill="#C7D0D8"/>

                                  <path d="M11.2699 8.88989H8.60986V9.9399H11.2699V8.88989Z" fill="#C7D0D8"/>
                                </svg>
                                <span> {{ d.type }}</span>
                              </span>
                            </a>
                          </div>
                          
                        </ng-container>                        
                      </mat-expansion-panel>
                    </mat-accordion>
                    <span><p></p></span>
                    <mat-accordion *ngIf="subgroup.invalidContents?.length > 0">
                      <mat-expansion-panel [expanded]="true" class="panel-training">
                        <mat-expansion-panel-header class="expansion-header" >
                          <!-- mobile panel -->
                          <mat-panel-title class="panel-header" *ngIf="checkIfComponentShouldUseMobileSize()">
                            <div>
                              <span class="completed-state">
                                <!-- completed icon -->
                                <svg *ngIf="subgroup.isCompleted" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="10" cy="10.5" r="10" fill="#66CC99"/>

                                  <g clip-path="url(#clip0_127_7305)">
                                    <path d="M8.62557 14.8272L4.90918 11.8235L5.57102 11.009L8.47282 13.3508L14.2765 6.17261L15.091 6.83442L8.62557 14.8272Z" fill="white"/>
                                  </g>

                                  <defs>
                                    <clipPath id="clip0_127_7305">
                                      <rect width="11.2" height="11.2" fill="white" transform="translate(4.3999 4.8999)"/>
                                    </clipPath>
                                  </defs>
                                </svg>
                                <!-- pending icon -->
                                <svg *ngIf="!subgroup.isCompleted" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M9.2002 4.30005V10.5L13.3002 15.6L14.4002 14.8L10.6002 10V4.30005H9.2002Z" fill="#6699CC"/>

                                  <path d="M18.6 10C18.5 14.8 14.6 18.6 9.9 18.6C5.2 18.6 1.3 14.7 1.3 10C1.3 5.3 5.2 1.4 9.9 1.4C11.4 1.4 12.9 1.8 14.1 2.5L13.2 3.3L19.1 6.4L16.4 0.4L15.2 1.5C13.7 0.5 11.9 0 10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10H18.6Z" fill="#6699CC"/>
                                </svg>                               
                              </span>                              
                            </div>
                          </mat-panel-title>

                          <!-- desktop panel -->
                          <mat-panel-title class="panel-header" *ngIf="!checkIfComponentShouldUseMobileSize()">
                            <span class="completed-state">
                              <!-- completed icon -->
                              <svg *ngIf="subgroup.isCompleted" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10" cy="10.5" r="10" fill="#66CC99"/>

                                <g clip-path="url(#clip0_127_7305)">
                                  <path d="M8.62557 14.8272L4.90918 11.8235L5.57102 11.009L8.47282 13.3508L14.2765 6.17261L15.091 6.83442L8.62557 14.8272Z" fill="white"/>
                                </g>

                                <defs>
                                  <clipPath id="clip0_127_7305">
                                    <rect width="11.2" height="11.2" fill="white" transform="translate(4.3999 4.8999)"/>
                                  </clipPath>
                                </defs>
                              </svg>
                              <!-- pending icon -->
                              <svg *ngIf="!subgroup.isCompleted" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.2002 4.30005V10.5L13.3002 15.6L14.4002 14.8L10.6002 10V4.30005H9.2002Z" fill="#6699CC"/>

                                <path d="M18.6 10C18.5 14.8 14.6 18.6 9.9 18.6C5.2 18.6 1.3 14.7 1.3 10C1.3 5.3 5.2 1.4 9.9 1.4C11.4 1.4 12.9 1.8 14.1 2.5L13.2 3.3L19.1 6.4L16.4 0.4L15.2 1.5C13.7 0.5 11.9 0 10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10H18.6Z" fill="#6699CC"/>
                              </svg>

                              Invalid
                            </span>                        
                          </mat-panel-title>

                        </mat-expansion-panel-header>
                        <ng-container *ngIf="subgroup.invalidContents?.length > 0">
                          <div style="margin-left: 20px; width: 914px; height: 40px; padding-top: 4px; padding-bottom: 4px; padding-left: 8px; padding-right: 16px; background: rgba(255, 255, 255, 0.80); box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15); border-radius: 8px; border: 1px white solid; backdrop-filter: blur(75px); justify-content: flex-start; align-items: center; gap: 16px; display: inline-flex">
                            <div style="width: 8px; height: 32px; background: #D11F1F; border-radius: 8px"></div>
                           <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M7 5.93934L5.93934 7L8.93934 10L5.93934 13L7 14.0607L10 11.0607L13 14.0607L14.0607 13L11.0607 10L14.0607 7L13 5.93934L10 8.93934L7 5.93934ZM18.5 6.17688L13.8231 1.5H6.17688L1.5 6.17688V13.4122L6.21056 18.48L13.7905 18.4967L18.5 13.4121V6.17688ZM20 5.55556V14L14.4444 19.9982L5.55556 19.9786L0 14.0016V5.55556L5.55556 0H14.4444L20 5.55556Z" fill="#D11F1F"/>
                            </svg>                              
                            <div class="invalidIlearnDiv">
                              <span class="invalidIlearn">
                              The information received from ilearn is incomplete , please contact ilearn helpdesk for resolving the issue.
                              </span>
                              <div style="width: 123px; height: 24px; padding-left: 16px; padding-right: 16px; padding-top: 6px; padding-bottom: 6px; background: white; border-radius: 40px; border: 1px #CCCFDE solid; justify-content: flex-start; align-items: center; gap: 4px; display: inline-flex">
                                <div style="text-align: center; color: #505575; line-height: 12px; letter-spacing: 0.48px; word-wrap: break-word">
                                  <a href="{{this.endpoint}}" target="_blank" style="text-decoration: none;" class="invalidIlearnButton">Contact Helpdesk</a></div>
                              </div>
                            </div>
                          </div>                                                    
                          <div class="training-container">
                            <!-- Loop through subgroup.invalidContents -->
                            <ng-container *ngFor="let d of subgroup.invalidContents">
                              <div class="detail-source card-container">
                                <a href="{{d.url}}" target="_blank" class="card">
                                  <!-- Your existing content for each item in the loop -->
                                  <div class="progress" [class.completed]="d.isCompleted">
                                    <mat-icon>error</mat-icon>Invalid
                                  </div>
                                  <h3 class="card-title">
                                    {{ d.name }}
                                  </h3>
                          
                                  <span *ngIf="d.isCompleted" class="show-date d-flex flex-column">
                                    <span>
                                      {{d.completionDate | date : 'mediumDate'}}
                                    </span>
                                    <span>
                                      {{d.txId}}
                                    </span>
                                  </span>
                                  
                                  <span class="card-span">
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M0 0V14.0001H14.0001V0H0ZM12.9501 12.9501H1.05001V1.05001H12.9501V12.9501Z" fill="#C7D0D8"/>
    
                                      <path d="M7.07002 3.43016L6.30001 2.66016L4.26999 4.62017L3.49999 3.85017L2.72998 4.62017L4.26999 6.23018L7.07002 3.43016Z" fill="#C7D0D8"/>
    
                                      <path d="M2.7998 11.3402H6.71984V7.42017H2.7998V11.3402ZM3.84981 8.47017H5.66983V10.2902H3.84981V8.47017Z" fill="#C7D0D8"/>
    
                                      <path d="M11.2699 3.98999H8.60986V5.04H11.2699V3.98999Z" fill="#C7D0D8"/>
    
                                      <path d="M11.2699 8.88989H8.60986V9.9399H11.2699V8.88989Z" fill="#C7D0D8"/>
                                    </svg>
                                    <span> {{ d.type }}</span>
                                  </span>
                                </a>
                              </div>
                            </ng-container>                                                     
                          </div>
                          
                          
                        </ng-container>
                      </mat-expansion-panel>
                    </mat-accordion>

                    <span *ngIf="idx !== item?.subGroups.length - 1" class="group-type">
                      {{item.type}}
                    </span>
                  </div>
                </ng-container>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </ng-template>

      <ng-template #emptyMessage>
        <p>
          No data available on this training, reach out your PSD manager.
        </p>
      </ng-template>
    </div>
  </section>
