import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterMyElementsService {

  private subject = new Subject<any>();
  private voidSubject = new Subject<void>();

  constructor() {
    //
  }

  public setValue(value: string) {
    this.subject.next({ value });
  }

  public onChange(): Observable<any> {
    return this.subject.asObservable();
  }

  public clear() {
    this.voidSubject.next();
  }
}
