<div class="detail-source pathway">
  <div *ngFor="let cert of certs" class="card" (click)="openLinkCerts(cert)" class="detail-source asessors">
    <h3 class="card-title">
      {{ cert.certName }}
    </h3>

    <span class=" card-title card-span">
      {{ cert.certId }}
    </span>
  </div>
</div>
