<ng-container *ngIf="templateRef; else default">
  <ng-template *ngTemplateOutlet="templateRef"></ng-template>
</ng-container>

<ng-template #default>

  <header class="header-mobile header container" [ngClass]="{'all-complete': isPromotionPage && allComplete}">

    <button class="go-back" (click)="goBack()" *ngIf="showBackButton">

      <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 17L1 9L9 1" stroke="white" stroke-opacity="0.6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>

      <span class="sr-only">Go back</span>

    </button>

    <pcp-page-title [title]="pageTitle" class="page-title"></pcp-page-title>

    <a routerLink="/home" class="go-home" *ngIf="showHomeButton">

      <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.9" d="M11.73 0L1.955 7.36L0 8.855L1.035 10.465L1.955 9.775V23H9.775V13.57H13.225V23H21.045V9.775L21.965 10.465L23 8.855L11.73 0ZM19.32 21.16H14.95V11.73H8.05V21.16H3.68V8.395L11.73 2.3L19.32 8.28V21.16Z" fill="white" />
      </svg>

      <span class="sr-only">Home</span>

    </a>

  </header>

</ng-template>

<header class="header-web">
  <div class="logo" routerLink="/home">
    <div class="logo-imgs">
      <img src="assets/images/slb-logo-blue.svg" alt="SLB brand">
      <span>MyPCP</span>
      <span *ngIf="envType" class="env-logo">
        {{envType}}
      </span>
      <div class="bar-logo"></div>
      <div class="title-bar">
        <span>Competency</span>
        <span>System</span>
      </div>
    </div>
  </div>

  <div class="user-info">
    <span class="name">{{ user?.name }}</span>
    <ng-container *ngIf="user?.avatar">
      <img class="image-icon" [src]="user?.avatar" (error)="handleImageError(user)" alt="user image" />
    </ng-container>
    <ng-container *ngIf="!user?.avatar">
      <img class="image-icon" src="assets/images/user-avatar-placeholder.png" alt="user image">
    </ng-container>
  </div>

</header>
