import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { FeedbackResultType } from '~/shared/enums/feedback-result-type.enum';

@Component({
  selector: 'pcp-spinner-feedback-dialog',
  templateUrl: './spinner-feedback-dialog.component.html',
  styleUrls: ['./spinner-feedback-dialog.component.scss']
})
export class SpinnerFeedbackDialogComponent implements OnInit, OnDestroy {
  isLoading: boolean = true;
  isLoadingResult$ = new Subject<{ isLoading: boolean, result: FeedbackResultType, message?: string }>();
  result: FeedbackResultType = FeedbackResultType.error;
  message: string = '';

  constructor(
    private dialogRef: MatDialogRef<SpinnerFeedbackDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {
      notLoading: boolean,
      result: FeedbackResultType,
      message: string
    }
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    if (this.data) {
      this.result = this.data.result;
      this.message = this.data.message;
      this.isLoading = false;
    }

    this.isLoadingResult$.subscribe((response: { isLoading: boolean, result: FeedbackResultType, message?: string }) => {
      this.result = response.result;
      this.isLoading = response.isLoading;
      if (response.message) {
        this.message = response.message;
      }
      this.dialogRef.disableClose = false;
    });
  }

  ngOnDestroy(): void {
    this.isLoadingResult$.unsubscribe();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  updateFeedbackResult(isLoading: boolean, result: FeedbackResultType, message?: string) {
    this.isLoadingResult$.next({ isLoading: isLoading, result: result, message: message });
  }

  isSuccess() {
    return !this.isLoading && this.result === FeedbackResultType.success;
  }

  isError() {
    return !this.isLoading && this.result === FeedbackResultType.error;
  }
}
