<section class="container" *ngIf="!pathwayCompleted && pathwayType !== 'T'">

  <div class="completed">
    <h2 class="title" *ngIf="!hasNoTemplates && quantityCompleted < quantityRequired">
      Pending
    </h2>

    <pcp-cert-card *ngIf="!pathwayCompleted && certs?.length > 0"
      [certs]="certs">
    </pcp-cert-card>

    <div class="detail-source"
      *ngIf="!pathwayCompleted && assessors?.length > 0 &&
              ((hasNoTemplates && !isAssessmentAllowed) || !hasNoTemplates) &&
              (pathwayType === 'CPA' || pathwayType === 'CTI')"
    >
      <p class="subtitle-detail-source">
        {{hasNoTemplates ? 'Assessors' : 'Select an Assessor'}}
      </p>

      <div class="assessors-filter-container">
        <app-generic-filter
          [showFilterSelection]="false"
          [isFilterApprover]="false"
          [dataSource]="assessors"
          (filtered)="applyAssessorsFilters($event)"
        >
        </app-generic-filter>
      </div>

      <div *ngFor="let assessor of filteredAssessors" class="detail-source asessors">
        <pcp-assessor-detail [name]="assessor.name"
          [unit]="assessor.unit" [job]="assessor.job"
          [isAssessorSelected]="isAssessorSelected(assessor.gin)"
          [status]="getAssessmentStatus(assessor.gin)"
          [hasAssessorSelected]="hasAssessorSelectedForAssessment"
          (onClickCard)="manageAssessorAssessmentCPA(assessor)"
          [pathwayType]="pathwayType"
          [isAssessmentAllowed]="isAssessmentAllowed"
          [isHover]="true">
        </pcp-assessor-detail>
      </div>
    </div>

    <pcp-element-incomplete-template *ngIf="!pathwayCompleted"
      [showNoTrainingDataMappedMessage]="!pathwayCompleted && noTrainingData && (!certs || certs?.length < 1)"
      [showNeedMoreExperienceMessage]="(!pathwayCompleted && needMoreExp && quantityCompleted < quantityRequired && !hasNoTemplates)"
      [showElementCompletedTemplateMessage]="(!pathwayCompleted && needMoreExp && quantityCompleted >= quantityRequired)"
      [showNoQualifiedAssessorsMessage]="(!pathwayCompleted && needAssessors && (!assessors || assessors?.length < 1) && !hasNoTemplates) || (hasNoTemplates && !isAssessmentAllowed && (assessors?.length ?? 0) < 1)"
      [showNoTemplatesAssociatedMessage]="hasNoTemplates && isAssessmentAllowed"
      [quantityCompleted]="quantityCompleted"
      [quantityRequired]="quantityRequired">
    </pcp-element-incomplete-template>
  </div>
</section>

<pcp-element-completed-template *ngIf="showElementCompletedTemplate()"
  [showQuantityProgress]="quantityCompleted && quantityRequired && !pathwayCompleted"
  [quantityRequired]="quantityRequired"
  [quantityCompleted]="quantityCompleted"
  [showNoDataAvailable]="!sources || sources?.length < 1"
  [sources]="sources">
</pcp-element-completed-template>

<pcp-empty-cpa-template *ngIf="hasNoTemplates && isAssessmentAllowed && pathwayType === 'CPA'">
</pcp-empty-cpa-template>

<pcp-training-content *ngIf="training?.groups?.length > 0 && pathwayType === 'T'"
  [training]="training"
  [pathwayCompleted]="pathwayCompleted">
</pcp-training-content>

