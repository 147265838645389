<div *ngIf="anyPromotionGroup()" [ngClass]="{'promotion-wrapper': !isHome, 'promotion-wrapper-home': isHome}"
  [class.page]="!isHome" [ngStyle]="{'flex-direction':isHome ? 'column' : 'row' }">

  <ng-container>
    <pcp-promotion-user-header *ngIf="isHome" [isHome]="isHome"></pcp-promotion-user-header>

    <div *ngIf="!isHome" class="container-user-promotion">
      <pcp-promotion-user-header [isHome]="isHome"></pcp-promotion-user-header>

      <div class="container-list">
        <ng-container *ngFor="let pg of promotion$?.promotionGroups; let idx = index;">
          <pcp-promotion-group  [isPromotion]="true" [isHome]="true"
            [group]="pg" (groupClick)="viewGroup($event)">
          </pcp-promotion-group>
          <pcp-grouping-type-line-breaker *ngIf="promotion$.groupingType && (idx != (promotion$.promotionGroups.length -1))" [groupingType]="promotion$.groupingType">
          </pcp-grouping-type-line-breaker>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <section (click)="navigateToPromotion()" [ngClass]="{'width-change': !isHome}">
    <div [ngClass]="{'container promotion-group-container promotion-group-container-not-home': !isHome,
    'container promotion-group-container': isHome}">
      <div *ngIf="anyPromotionGroup()">

        <ng-container *ngFor="let group of promotion$?.promotionGroups; let idx = index;">
          <pcp-promotion-group [isHome]="isHome" [group]="group"
            (groupClick)="viewGroup($event)">
          </pcp-promotion-group>
          <pcp-grouping-type-line-breaker *ngIf="isHome && promotion$.groupingType && (idx != (promotion$.promotionGroups.length -1))" [groupingType]="promotion$.groupingType">
          </pcp-grouping-type-line-breaker>
        </ng-container>

        <mat-accordion *ngIf="!isHome" multi>
          <div *ngFor="let group of promotion$.promotionGroups; index as groupIndex">
            <mat-expansion-panel [expanded]="group.name === groupParam && stateOfPanel === true"
              class="panel-promotion">
              <mat-expansion-panel-header class="panel-header-big-arrow">
                <mat-panel-title class="title">
                  <div class="header-panel-title">
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"
                      *ngIf="group.groupResult === 'Fail'">
                      <path d="M13.8008 6.44995V15.75L19.9508 23.4L21.6008 22.2L15.9008 15V6.44995H13.8008Z"
                        fill="#6699CC" />
                      <path
                        d="M27.9 15C27.75 22.2 21.9 27.9 14.85 27.9C7.8 27.9 1.95 22.05 1.95 15C1.95 7.95 7.8 2.1 14.85 2.1C17.1 2.1 19.35 2.7 21.15 3.75L19.8 4.95L28.65 9.6L24.6 0.6L22.8 2.25C20.55 0.75 17.85 0 15 0C6.75 0 0 6.75 0 15C0 23.25 6.75 30 15 30C23.25 30 30 23.25 30 15H27.9Z"
                        fill="#6699CC" />
                    </svg>
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"
                      *ngIf="group.groupResult !== 'Fail'">
                      <circle cx="15" cy="15" r="15" fill="#66CC99" />
                      <path
                        d="M12.9388 21.4909L7.36426 16.9854L8.35702 15.7636L12.7097 19.2763L21.4152 8.50903L22.637 9.50175L12.9388 21.4909Z"
                        fill="white" />
                    </svg>
                    <p>{{group.name}}</p>
                  </div>
                  <mat-divider></mat-divider>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <pcp-promotion-item-list [isHome]="isHome" [requirements]="group.requirements"
                [groupingType]="group.groupingType">
              </pcp-promotion-item-list>
            </mat-expansion-panel>
            <pcp-grouping-type-line-breaker *ngIf="promotion$.groupingType && (groupIndex != (promotion$.promotionGroups.length -1))" [groupingType]="promotion$.groupingType">
            </pcp-grouping-type-line-breaker>
          </div>
        </mat-accordion>
      </div>
    </div>

    <div *ngIf="isHome" class="container-view-more">
      <pcp-view-more></pcp-view-more>
    </div>
  </section>

  <pcp-competency-detail *ngIf="!isHome"></pcp-competency-detail>

</div>

<div *ngIf="!isHome && !promotion$">
  <pcp-no-data-found></pcp-no-data-found>
</div>
