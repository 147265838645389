import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'pcp-select-filter',
  templateUrl: './select-filter.component.html',
  styleUrls: ['./select-filter.component.scss']
})
export class SelectFilterComponent implements OnInit {

  get hasSelectedKey(): boolean {
    return this.selectedKey !== undefined && this.selectedKey !== null;
  }

  get keys(): any[] {

    const objKeys = (this.filteredDataSource || []).map((i) => Object.keys(i).filter((key) => this.labelKeys.indexOf(key) !== -1));

    const dsKeys = objKeys.length > 0 ? objKeys.reduce((a, b) => a.concat(b)) : [];

    const availableKeys = dsKeys.filter((k) => !(this.filters || []).some((f) => f.key === k));

    const filteredAvailableKeys = availableKeys.filter((v, i, a) => a.indexOf(v) === i);

    return filteredAvailableKeys;
  }

  get values(): string[] {
    if (this.filteredDataSource.length > 0 && Array.isArray(this.filteredDataSource[0][this.selectedKey])) {

      const list = [];
      this.filteredDataSource
        .filter((i) => Array.from(i[this.selectedKey]).length > 0)
        .map((i) => Array.from(i[this.selectedKey]))
        .forEach((item) => item.forEach((i) => list.push(i)));

      return [... new Set(list)].sort();
    }

    const data: string[] = (this.filteredDataSource || []).map((i) => i[this.selectedKey]).filter((v, i, a) => a.indexOf(v) === i);
    return data;
  }

  @Input()
  public labels: Array<any> = [];
  @Input()
  public labelKeys: Array<string> = [];

  @Input()
  public filteredDataSource: Array<any>;

  @Input()
  public filters;

  public selectedKey;

  public selectedValue = new FormControl();
  public filteredOptions: Observable<string[]>;

  public optionsLoading = false;

  @Output()
  public keyValueSelect = new EventEmitter<{ key, value }>();

  private _filter(value: string): string[] {
    const filterValue = value.toString().toLowerCase();
    return this.values.filter((option) => option?.toString().toLowerCase().includes(filterValue));
  }

  public ngOnInit() {
    this.filteredOptions = this.selectedValue.valueChanges
      .pipe(
        startWith(''),
        map((value) => (value ? this._filter(value) : this.values.slice()))
      );
  }

  public clear(): void {
    this.selectedKey = null;
    this.selectedValue.reset()
  }

  public valueSelected(): void {
    this.keyValueSelect.emit({ key: this.selectedKey, value: this.selectedValue.value });
    this.clear();
  }

  public getLabel(key): any {
    const lbl = this.labels.find((l) => Object.keys(l)[0] === key);
    if (lbl) {
      return Object.values(lbl)[0];
    }
    return key;
  }
}
