<div class="container" *ngIf="!isLoading && hasContent">

  <mat-accordion>
    <mat-expansion-panel [expanded]="panelOpenState" (opened)="panelOpenState = true" (closed)="panelOpenState = false"
      class="panel-expiration">
      <mat-expansion-panel-header class="expansion-header">
        <mat-panel-title *ngIf="!panelOpenState">
          <h2 class="title">SHOW HISTORY</h2>
        </mat-panel-title>
        <mat-panel-title *ngIf="panelOpenState">
          <h2 class="title">HIDE HISTORY</h2>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container>
        <div class="timeline-container">
          <pcp-history-timeline *ngIf="panelOpenState" [history]="history"></pcp-history-timeline>
        </div>
      </ng-container>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
    <mat-expansion-panel [expanded]="panelOpenStatePL" (opened)="panelOpenStatePL = true" (closed)="panelOpenStatePL = false"
      class="panel-expiration">
      <mat-expansion-panel-header class="expansion-header">
        <mat-panel-title *ngIf="!panelOpenStatePL">
          <h2 class="title">SHOW PROFICIENCY LEVEL </h2>
        </mat-panel-title>
        <mat-panel-title *ngIf="panelOpenStatePL">
          <h2 class="title">HIDE PROFICIENCY LEVEL</h2>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container>
        <section class="container-information">
          <div *ngIf="pl" id="information-level" class="content-information">
            <label>Level:</label>
            <span>{{pl.name}}</span>
          </div>

          <div *ngIf="plTarget" id="information-target" class="content-information">
            <label>Target:</label>
            <span>{{plTarget.name}}</span>
          </div>

          <div *ngIf="awardedOnDate" class="content-information information-lg">
            <label>Awarded on:</label>
            <span class="date">{{awardedOnDate | date:'mediumDate'}}</span>
          </div>

          <div *ngIf="nextExpirationDate" class="content-information information-lg">
            <label>Expires On:</label>
            <span class="date">{{nextExpirationDate| date:'mediumDate'}}</span>
          </div>
        </section>
      </ng-container>
    </mat-expansion-panel>
  </mat-accordion>


  <section id="container-level-information" *ngIf="nextLevelRequirements?.length > 0">
    <pcp-level-information [pcpRequirements]="nextLevelRequirements" (onClickOpenDetailDialog)="openDialogDetail($event)">
    </pcp-level-information>
  </section>

  <section *ngIf="currentLevelRequirements?.length > 0">
    <pcp-expiration-details [pcpRequirements]="currentLevelRequirements" (onClickOpenDetailDialog)="openDialogDetail($event)">
    </pcp-expiration-details>
  </section>

  <pcp-warning-card [competencyElementName]="competencyElementName" [competencyElementId]="warningCeId"></pcp-warning-card>
</div>
<div *ngIf="!isLoading && !hasContent">
  <div class="content-information information-lg">
    <label>This CE does not have any requirements, reach out to your local FDE or Competency Manager for more
      details.</label>
  </div>
</div>
