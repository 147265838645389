import { Component, Input } from '@angular/core';

@Component({
  selector: 'pcp-element-incomplete-template',
  templateUrl: './element-incomplete-template.component.html',
  styleUrls: ['./element-incomplete-template.component.scss']
})
export class ElementIncompleteTemplateComponent {
  @Input() showNoTrainingDataMappedMessage: boolean = false;
  @Input() showNeedMoreExperienceMessage: boolean = false;
  @Input() showElementCompletedTemplateMessage: boolean = false;
  @Input() showNoQualifiedAssessorsMessage: boolean = false;
  @Input() showNoTemplatesAssociatedMessage: boolean = false;
  @Input() showNoAssessors: boolean = false;
  @Input() quantityCompleted: number = 0;
  @Input() quantityRequired: number = 0;
}
