import { ResultResponseType } from "~/shared/enums/result-response-type.enum";

export class ResponseItemDto<T> {
  result: ResultResponseType;
  item: T;
  items: T[];
  error: T;

  constructor(init?: Partial<ResponseItemDto<T>>) {
    Object.assign(this, init);
  }
}
