import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-confirmation',
  templateUrl: './dialog-confirmation.component.html',
  styleUrls: ['./dialog-confirmation.component.scss']
})
export class DialogConfirmationComponent implements OnInit {

  get textCancelButton(): string {
    return this.data.textCancelButton ? this.data.textCancelButton : 'CANCEL';
  }

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: {
      headerMessage,
      message,
      submessage,
      name,
      color,
      textConfirmButton,
      textCancelButton,
      hideCancelButton
    }
  ) { }

  ngOnInit(): void {
    //
  }

  close(): void {
    this.dialogRef.close(null);
  }

  confirm(): void {
    this.dialogRef.close(true);
  }

  postPone(): void {
    this.dialogRef.close(false);
  }

}
