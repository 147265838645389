import { Injectable } from '@angular/core';
import { CompetencyApiBaseService } from '~/shared/services/base/competency-api-base.service';
import { Configuration } from '~/shared/models/configuration.model';
import { HttpClient } from '@angular/common/http';
import { TokenQuery } from '~/store/token/token.query';
import { UserSentimentSurvey } from '~/shared/models/user-sentiment-survey.model';
import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmationComponent } from '~/shared/components/dialog-confirmation/dialog-confirmation.component';

export class DataTypePCE {
  requirementType: string;
  plTarget: string;
}
@Injectable({
  providedIn: 'root'
})
export class UserSentimentSurveyService extends CompetencyApiBaseService<any> {

  private postponedUsersArray = JSON.parse(localStorage.getItem('postponedUsers')) as Array<UserSentimentSurvey> || [] as Array<UserSentimentSurvey>;

  constructor(
    config: Configuration,
    httpClient: HttpClient,
    token: TokenQuery,
    private dialog: MatDialog
  ) {
    super(httpClient, config, token)
  }

  public startUserSentimentSurveyFlow(currentUserGin: string) {
    this.userNeedsToRespondUserSentimentSurvey(currentUserGin).subscribe((ussResponse: UserSentimentSurvey) => {

      const isUserPostponeValid = this.validateUserSentimentSurveyPostpone(currentUserGin);
        if (!isUserPostponeValid && ussResponse.isEligible) {
          const USSDialog = this.dialog.open(DialogConfirmationComponent, {
            data: {
              headerMessage: 'User Sentiment Survey',
              message: 'Please Take a Moment and Participate in the User Sentiment Survey',
              submessage: 'You can postpone this survey for another 14 days',
              textConfirmButton: 'CONFIRM',
              textCancelButton: 'POSTPONE'
            }
          })

          USSDialog.afterClosed().subscribe(response => {
            if (response) {
              this.showUserSentimentSurveyWithUrl(ussResponse.urlToRedirect);
            } 
            else if (response == false)
            { 
              this.saveUserUSSOptionsInLocalStoarage(currentUserGin);
            }
          })
        }

    })
  }

  private isUserUSSOptionsInLocalStorage(currentUserGin: string): boolean {
    if (this.postponedUsersArray?.findIndex((item) => item.userGinNumber === currentUserGin) > -1) {
      return true;
    }
    return false;
  }

  private saveUserUSSOptionsInLocalStoarage(currentUserGin: string) {

    let user = this.loadUserUSSOptionsInLocalStoarage(currentUserGin) as UserSentimentSurvey;

    if(!user) {
      user = new UserSentimentSurvey();
      user.userGinNumber = currentUserGin;
      const currentDate = new Date();
      user.postponedDate = new Date(currentDate.setDate(currentDate.getDate() + 14)).toString(); 
      this.postponedUsersArray.push(user);
    } else {
      user.postponedDate = new Date().toISOString();
    }

    localStorage.setItem('postponedUsers', JSON.stringify(this.postponedUsersArray));
  }

  private loadUserUSSOptionsInLocalStoarage(currentUserGin: string): UserSentimentSurvey {
    return this.postponedUsersArray?.find((item) => item.userGinNumber === currentUserGin);
  }

  private validateUserSentimentSurveyPostpone(currentUserGin: string): boolean {
    const currentDate = new Date();
  
    let validity = false;

    if(this.isUserUSSOptionsInLocalStorage(currentUserGin)) {
      const userData = this.loadUserUSSOptionsInLocalStoarage(currentUserGin);
      return (new Date(userData.postponedDate) >= currentDate); 
    }
    return validity;
  }

  private userNeedsToRespondUserSentimentSurvey(UserId: string) {
    return this.getUSSingleCustom(`${this.config.ussApiUrl}/survey/check-eligibility/EAR-AA-8413/${UserId}`);
  }

  private showUserSentimentSurveyWithUrl(url: string) {
    window.open(url, 'newwindow', 'width=900,height=850,toolbar=0,menubar=0,location=0,top=10,left=25');
  }
}
