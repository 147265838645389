import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ElementLevelInfo as ElementDetailBase } from '../../../../shared/models/element-level-info.model';
import { isMobileSize } from '../../../../shared/utils/navigator';
import { PcpRequirements } from '../../../../shared/models/pcp-requirements.model';

@Component({
  selector: 'pcp-level-information',
  templateUrl: './level-information.component.html',
  styleUrls: ['./level-information.component.scss']
})
export class LevelInformationComponent {
  private awardedOnDate: Date;

  public panelOpenState = isMobileSize() ? false : true;
  @Input() public competencyElementName: string;
  @Input() public pcpRequirements:PcpRequirements;
  @Output() onClickOpenDetailDialog = new EventEmitter<{data: any, type: string, awardedOnDate: Date }>();
  public messages: Array<string> = [];
  public levelInfosReasonFour: Array<ElementDetailBase> = [];
  public hasReasonOneAndThree = false;
  public hasReasonOne = false;
  public hasReasonTwo = false;
  public hasReasonThree = false;
  public hasReasonFour = false;


  constructor(
    public dialog: MatDialog
  ) { }

  public openDialog(data: PcpRequirements): void {
    this.onClickOpenDetailDialog.emit({
      data: data,
      type: 'training',
      awardedOnDate: this.awardedOnDate
    });
  }
}
