import { Directive, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { PullToRefreshService } from '../../core/services/pull-to-refresh.service';

// TODO: Add Angular decorator.
@Directive()
export abstract class PageComponentBase implements OnDestroy {
  private refreshRequestedSubscription: Subscription;

  constructor(
    private pullToRefreshService: PullToRefreshService
  ) {
    this.refreshRequestedSubscription = pullToRefreshService.$refreshRequested
      .subscribe(() => this.refreshRequested());
  }

  public refreshRequested(): void {
    this.finishLoading();
  }

  public finishLoading(): void {
    this.pullToRefreshService.finishLoading();
  }

  public ngOnDestroy(): void {
    this.refreshRequestedSubscription.unsubscribe();
  }
}
