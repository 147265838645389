import { Component, Input, OnInit } from '@angular/core';
import { Trend } from '~/shared/models/trend.model';
import { TrendQuery } from '~/store/trend/trend.query';
import { Chart } from 'chart.js';
import { CompiledAverageMonthlyData } from '~/shared/models/compiled-average-monthly-data.model';
import { MonthlyCompliance } from '~/shared/models/monthly-compliance.model';
import { MyTrendsService } from '~/shared/services/my-trends.service';

@Component({
  selector: 'compliance-user-trend-chart',
  templateUrl: './compliance-user-trend-chart.component.html',
  styleUrls: ['./compliance-user-trend-chart.component.scss']
})
export class ComplianceUserTrendChartComponent implements OnInit {

  private trend$: Trend;
  private lastSixMonthsAverage = 0;
  private firstEighteenMonthsAverage = 0;
  private chartColumnMonthResolution = 24;
  private chartLabels = [];
  private chartBackgroundColor = [
    'rgba(30, 255, 0, 0.3)',
    'rgba(30, 255, 0, 0.3)',
    'rgba(30, 255, 0, 0.3)',
    'rgba(30, 255, 0, 0.3)',
    'rgba(30, 255, 0, 0.3)',
    'rgba(30, 255, 0, 0.3)',
    'rgba(30, 255, 0, 0.3)',
    'rgba(30, 255, 0, 0.3)',
    'rgba(30, 255, 0, 0.3)',
    'rgba(30, 255, 0, 0.3)',
    'rgba(30, 255, 0, 0.3)',
    'rgba(30, 255, 0, 0.3)',
    'rgba(30, 255, 0, 0.3)',
    'rgba(30, 255, 0, 0.3)',
    'rgba(30, 255, 0, 0.3)',
    'rgba(30, 255, 0, 0.3)',
    'rgba(30, 255, 0, 0.3)',
    'rgba(30, 255, 0, 0.3)',
    'rgba(0, 120, 255, 0.3)',
    'rgba(0, 120, 255, 0.3)',
    'rgba(0, 120, 255, 0.3)',
    'rgba(0, 120, 255, 0.3)',
    'rgba(0, 120, 255, 0.3)',
    'rgba(0, 120, 255, 0.3)'
  ];

  @Input() public _mobile = false;
  @Input() set mobile(value: boolean) {
    this._mobile = value;
    this.trendService.initializeTrendChart(this._mobile, true);
  }

  public hasTrendData = false;
  public chartHeight = '600px';
  public chartWidth = '100%';

  constructor(
    private trendQuery: TrendQuery,
    private trendService: MyTrendsService
  ) { }

  private initializeChart(): void {
    this.hasTrendData = false;

    this.trendQuery.select().subscribe((state) => {
      this.trend$ = state.trend;
    });

    // Break Reference
    const loadedTrendData = JSON.parse(JSON.stringify(this.trend$));

    const loadedDataIsNotEmpty = loadedTrendData?.monthlyCompliance.length > 0;

    if (loadedDataIsNotEmpty) {
      this.hasTrendData = true;
    } else {
      this.hasTrendData = false;
    }
  }

  public ngOnInit(): void {
    this.initializeChart();
    this.trendService.initializeTrendChart(this._mobile, true);
  }
}
