import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserQuery } from '~/store/user/user.query';
import { ElementAssessment } from '~/shared/models/element-assessment.model';
import { CompetencyDetailsService } from '~/shared/services/competency-details.service';
import { ElementDetails, Training } from '~/shared/models/competency-details.model';
import { AssessmentService } from '~/shared/services/assessment.service';
import { isMobileSize } from '~/shared/utils/navigator';
import { ResponseItemDto } from '~/shared/services/dtos/response-item.dto';
import { ResultResponseType } from '~/shared/enums/result-response-type.enum';
import { ErrorResponse } from '~/shared/services/dtos/error.dto';
import { AssessmentDetails } from '~/shared/models/assessment-details.model';

@Component({
  selector: 'pcp-dialog-detail',
  templateUrl: './dialog-detail.component.html',
  styleUrls: ['./dialog-detail.component.scss']
})
export class DialogDetailComponent implements OnInit {

  private ginNumber: number;
  public competencyElementName: string;
  public pathwayRequirement: string;
  public competencyDetail: any;
  public training: Training;
  public items: Array<ElementAssessment>;
  public elementId: number;
  public isAssessmentAllowed: boolean;
  private pathway: string;
  public pl: string;
  quantityRequired: number;
  quantityCompleted: number;
  pathwayType: string;
  isLoading: boolean = true;
  pathwayCompleted: string = "";
  hasNoTemplates: boolean = false;

  constructor(public dialogRef: MatDialogRef<DialogDetailComponent>,
    private userQuery: UserQuery,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private competencyDetailsService: CompetencyDetailsService,
    private assessmentService: AssessmentService
    ) { }


  public ngOnInit(): void {
    this.ginNumber = Number(this.userQuery.getValue().user.ginNumber ?? 0);
    this.competencyElementName = this.data.competencyElementName;
    this.pathwayRequirement = this.data.pathwayRequirement;
    this.elementId = this.data.competencyElementId;
    this.pathway = this.data.pathway;
    this.pathwayType = this.data.pathwayType;
    this.pathwayCompleted = this.data?.pathwayCompleted;
    this.isAssessmentAllowed = this.data?.isAssessmentAllowed;
    this.quantityCompleted = this.data?.quantityCompleted;
    this.quantityRequired = this.data?.quantityRequired;
    this.pl = this.data.pl;

    if (this.pathwayType === "T") {
      this.competencyDetailsService
      .getTrainings(this.ginNumber, this.elementId, this.pl, this.pathway)
      .subscribe({
        next: (data: any) => {
          if (data) {
            this.training = data
            this.isLoading = false;
          } else {
            this.setErrorParameters();
          }
        },
        error: () => this.isLoading = false
      });
    } else {
      this.competencyDetailsService.getElementDetails(this.ginNumber, this.pl, this.elementId, this.pathway, this.pathwayType)
      .subscribe(
        {
          next: (data: ResponseItemDto<ElementDetails | ErrorResponse>) => this.handleGetElementDetailsNext(data),
          error: () => this.isLoading = false
        }
      );
    }
  }

  handleGetElementDetailsNext(data: ResponseItemDto<ElementDetails | ErrorResponse>) {
    if (data?.result === ResultResponseType.success) {
      this.competencyDetail = data.item as ElementDetails;
      if (this.pathwayType === 'CPA') {
        this.checkIfIsEnableCPAAndGetAssessment();
      } else {
        this.isLoading = false;
      }
    }
    else {
      this.competencyDetail = new ElementDetails(
        {
          sources:[],
          certs:[],
          assessors:[],
          assessmentDetails: new AssessmentDetails()
        }
      );
      this.isLoading = false;
    }
  }

  checkIfComponentShouldUseMobileSize() {
    return isMobileSize();
  }

  checkIfIsEnableCPAAndGetAssessment() {
    if (this.data.isAssessmentAllowed === true) {
      this.assessmentService.getCatAssessment(this.elementId)
      .subscribe({
        next: (assessmentTemplate) => {
          if (assessmentTemplate) {
            this.hasNoTemplates = false;
            this.assessmentService.getAssessment(this.ginNumber, this.elementId, this.pl)
              .subscribe(assessmentDetails => {
                this.competencyDetail.assessmentDetails = assessmentDetails;
                this.isLoading = false;
              });
          } else {
            this.setErrorParameters();
          }
        },
        error: () => this.isLoading = false
      });
    } else {
      this.setErrorParameters();
    }
  }

  setErrorParameters() {
    this.hasNoTemplates = true;
    this.pathwayRequirement = 'Error';
    this.isLoading = false;
  }

  public close(): void {
    this.dialogRef.close();
  }
}
