import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'pcp-grouping-type-line-breaker',
  templateUrl: './grouping-type-line-breaker.component.html',
  styleUrls: ['./grouping-type-line-breaker.component.scss']
})
export class GroupingTypeLineBreakerComponent {
  @Input() groupingType: string;
  constructor() { }
}
