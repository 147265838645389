<section [class.card-hover]="isCPA" (mouseenter)="isCPA ? itemIsBeingHovered = true : undefined" (mouseleave)="isCPA ? itemIsBeingHovered = false : undefined">
  <div [ngClass]="{ 'disabled-card': !isAssessorSelected && hasAssessorSelected, 'cursor-pointer': isCPA }"
    class="assessor-section"
    (click)="isCPA ? clickOnCard() : undefined">
    <div class="assessor-info-container">
      <div class="name">
        {{ name }}
      </div>
      <div class="assessor-info">
        <div>
          <p>{{ job }}</p>
          <p>{{ unit }}</p>
        </div>
        <div>
          <p class="status">{{ status }}</p>
        </div>
      </div>
    </div>
    <div class="icon-container" [ngClass]="{'hidden-button': shouldHideIcon() || !isCPA }">
      <div class="icon"
        [ngClass]="{'request-assessment': shouldShowStartIcon(),
          'created-assessment': shouldShowCreatedIcon(),
          'cancel-assessment': shouldShowCancelIcon(),
          'ongoing-assessment': shouldShowOngoingIcon(),
          'completed-assessment': shouldShowCompletedIcon()}">
        <mat-icon *ngIf="shouldShowStartIcon()">schedule</mat-icon>
        <mat-icon *ngIf="shouldShowCreatedIcon()">history</mat-icon>
        <mat-icon *ngIf="shouldShowCancelIcon()">highlight_off</mat-icon>
        <mat-icon *ngIf="shouldShowOngoingIcon()">loop</mat-icon>
        <mat-icon *ngIf="shouldShowCompletedIcon()">done</mat-icon>
      </div>
    </div>
  </div>
</section>
