<div class="detail-source">
    <p *ngIf="showNoTrainingDataMappedMessage">
      No training data mapped in Competency, please reach out to local PSD Manager, and FDE for further action.
    </p>

    <p *ngIf="showNeedMoreExperienceMessage">
      You need to gain more experience to meet the proficiency level requirements.
    </p>

    <pcp-element-completed-template *ngIf="showElementCompletedTemplateMessage"
      [showQuantityProgress]="true"
      [quantityCompleted]="quantityCompleted"
      [quantityRequired]="quantityRequired"
      [showNoDataAvailable]="true">
    </pcp-element-completed-template>

    <p *ngIf="showNoQualifiedAssessorsMessage">
      No qualified assessors available for this CE in your area. Please contact your local PSD Manager and FDE for further action.
    </p>

    <p *ngIf="showNoTemplatesAssociatedMessage">
      There are no templates associated with this assessment.
    </p>

    <p *ngIf="showNoAssessors">
      There are no assessors associated with this assessment.
    </p>
  </div>
