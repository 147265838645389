<section>
  <div class="content-item">
    <div class="container-title">
      <div class="title">
        <span>{{competencyElement.competencyUnit}}</span>
        <span>{{competencyElement.name}}</span>
      </div>
    </div>
    <div class="points">
      <pcp-display-proficiency-level [competencyElement]="competencyElement" [requirementType]="requirement.requirementType" ></pcp-display-proficiency-level>
    </div>
  </div>
</section>
