import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AssessmentStatus } from '~/shared/enums/assessment-status.enum';

@Component({
  selector: 'pcp-assessor-detail',
  templateUrl: './assessor-detail.component.html',
  styleUrls: ['./assessor-detail.component.scss']
})
export class AssessorDetailComponent implements OnInit {

  @Input() name:string;
  @Input() unit:string;
  @Input() job:string;
  @Output() onClickCard = new EventEmitter<void>();
  @Input() isHover = false;
  @Input() status: string;
  @Input() isAssessorSelected: boolean = false;
  @Input() hasAssessorSelected: boolean = false;
  @Input() pathwayType: string;
  @Input() isAssessmentAllowed: boolean = false;
  itemIsBeingHovered = false;

  ngOnInit(): void {
    if (!this.pathwayType) {
      return;
    }
  }

  shouldShowStartIcon() {
    return this.isAssessorSelected === false
      && this.itemIsBeingHovered === true
      && this.isAssessmentAllowed === true;
  }

  shouldShowCreatedIcon() {
    return (this.isAssessorSelected === true &&
      this.itemIsBeingHovered === false &&
      this.isAssessmentAllowed === true &&
      Number(AssessmentStatus[this.status]) === AssessmentStatus.Created);
  }

  shouldShowCancelIcon() {
    return (this.isAssessorSelected === true &&
      this.itemIsBeingHovered === true &&
      this.isAssessmentAllowed === true &&
      (Number(AssessmentStatus[this.status]) === AssessmentStatus.Created ||
      Number(AssessmentStatus[this.status]) === AssessmentStatus.Rejected));
  }

  shouldShowCompletedIcon() {
    return (this.isAssessorSelected === true &&
      this.isAssessmentAllowed === true &&
      Number(AssessmentStatus[this.status]) === AssessmentStatus.Completed);
  }

  shouldShowOngoingIcon() {
    return (this.isAssessorSelected === true &&
      this.isAssessmentAllowed === true &&
      Number(AssessmentStatus[this.status]) === AssessmentStatus['In Progress']);
  }

  shouldHideIcon() {
    return (this.hasAssessorSelected && !this.isAssessorSelected) ||
      this.isHover === false ||
      this.isAssessmentAllowed === false;
  }

  clickOnCard() {
    const emitForRemoval = this.hasAssessorSelected &&
      this.isAssessorSelected &&
      (Number(AssessmentStatus[this.status]) === AssessmentStatus.Created || Number(AssessmentStatus[this.status]) === AssessmentStatus.Rejected);
    const emitForRequestAssessment = !this.hasAssessorSelected;
    if(emitForRemoval || emitForRequestAssessment) {
      this.onClickCard.emit();
    }
  }

  get isCPA() {
    return this.pathwayType === 'CPA';
  }
}
