
import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { ITargetV2State, TargetV2Store } from './targetV2.store';

@Injectable({ providedIn: 'root' })
export class TargetV2Query extends QueryEntity<ITargetV2State> {
  public targetV2$ = this.select((state) => state.targetV2);

  constructor(protected store: TargetV2Store) {
      super(store);
  }

}
