<div class="info-item">

  <div class="container-information">
    <ng-container *ngIf="templateRef; else default">
      <ng-template *ngTemplateOutlet="templateRef"></ng-template>
    </ng-container>

    <ng-template #default>
      <img class="icon" [src]="iconSrc" [alt]="iconAlt" />
    </ng-template>

    <span *ngIf="label" class="label"
      [ngClass]="{ 'fontSize3': size === 'L', 'fontSize4': size === 'M', 'fontSize5': size === 'S'}">{{ label }}</span>
  </div>

  <!-- CR1497-start -->
  <a *ngIf="hyperLinkForValue; else elseBlock" href="{{hyperLinkForValue}}" target="_blank">
    <span class="value tooltip"
      [ngClass]="{ 'fontSize3': size === 'L', 'fontSize4': size === 'M', 'fontSize5': size === 'S'}"
      [class.only-value]="!label">{{ value }}
    </span>
  </a>

  <ng-template #elseBlock>
    <span class="value" [ngClass]="{ 'fontSize3': size === 'L', 'fontSize4': size === 'M', 'fontSize5': size === 'S'}"
      [class.only-value]="!label">{{ value }}</span>
  </ng-template>
  <!-- CR1497-end -->

</div>
