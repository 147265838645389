import { Inject, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { InteractionRequiredAuthError, IPublicClientApplication, RedirectRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { Configuration } from '~/shared/models/configuration.model';
import { TokenStore } from '~/store/token/token.store';
import { LocalStorageKeys } from '../../shared/constants/local-storage-keys';
import { AppInsightsService } from './app-insights.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public msalInstance: IPublicClientApplication;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private injector: Injector,
    private router: Router,
    private config:Configuration,
    private tokenStore:TokenStore
  ) {
    this.msalInstance = msalService.instance;
  }

  /**
   * Register a callback that is called before the login redirect.
   * The Msal Angular lib throws an error if the callback is not registered.
   */

  private errorRegisterRedirectCallback(error) {
    this.injector.get(AppInsightsService).trackException(error);
    this.logout();
    this.router.navigateByUrl('/login');
  }

  public checkAndSetActiveAccount() {
    const activeAccount = this.msalInstance.getActiveAccount();

    if (!activeAccount && this.msalInstance.getAllAccounts().length > 0) {
      const accounts = this.msalInstance.getAllAccounts();
      this.msalInstance.setActiveAccount(accounts[0]);
    }
  }

  public async login() {
    const accounts = this.msalInstance.getAllAccounts();

    if (accounts.length === 0) {
      if (this.msalGuardConfig.authRequest){
        this.msalService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
      } else {
        this.msalService.loginRedirect();
      }
    } else {
      this.checkAndSetActiveAccount();
      this.router.navigate(['home']);
    }
  }

  public isAuthenticated(): boolean {
    return this.msalInstance.getActiveAccount() ? true : false;
  }

  public logout(): void {
    for (const key of Object.keys(LocalStorageKeys)) {
      localStorage.removeItem(LocalStorageKeys[key]);
    }

    const appInsights = this.injector.get(AppInsightsService);
    appInsights.clearUserId();
    this.msalService.logout();
  }

  public getEmail(): string {
    if (this.isAuthenticated()) {
      return this.msalInstance.getActiveAccount().username;
    } else {
      throw new Error('User has to be authenticated.');
    }
  }

  public getGinNumber(): number {
    return localStorage.getItem(LocalStorageKeys.GinNumber) ?
      parseInt(localStorage.getItem(LocalStorageKeys.GinNumber), null) : 0;
  }

  public setGinNumber(ginNumber: number): void {
    localStorage.setItem(LocalStorageKeys.GinNumber, ginNumber.toString());

  }

  public registerRedirectCallback(): Subject<void> {

    const subject = new Subject<void>();

    const authority = `https://login.microsoftonline.com/${this.config.azureTenantId}`;
    const account = this.msalInstance.getActiveAccount();

    const graphScopes = {
      scopes: [this.config.apiScope],
      authority: authority,
      account: account
    };

    const esmApiScope = {
      scopes: [this.config.esmApiScope],
      authority: authority,
      account: account
    };

    const casApiScope = {
      scopes : [this.config.casApiScope],
      authority: authority,
      account: account
    }

    const ussApiScope	= {
      scopes: [this.config.ussApiScope],
      authority: authority,
      account: account
    }

    const catApiScope	= {
      scopes: [this.config.catApiScope],
      authority: authority,
      account: account
    }

    this.msalInstance.handleRedirectPromise()
      .then((data) => {
        this.msalService.acquireTokenSilent(graphScopes).toPromise().then((data) => {
            this.tokenStore.update({ msalApiToken: data?.accessToken });
            subject.next()
            subject.complete()
          }).catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
              this.msalService.acquireTokenPopup(graphScopes)
                .toPromise()
                .then((data) => {
                  this.tokenStore.update({ msalApiToken: data.accessToken });
                  subject.next()
                  subject.complete()
                }).catch((error) => this.errorRegisterRedirectCallback(error));
            } else this.errorRegisterRedirectCallback(error)
          });

        this.msalService.acquireTokenSilent(esmApiScope).toPromise().then(data => {
            this.tokenStore.update({ esmApiToken: data?.accessToken });
            subject.next()
            subject.complete()
          }).catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {

              this.msalService.acquireTokenPopup(esmApiScope)
                .toPromise()
                .then((data) => {
                  this.tokenStore.update({ esmApiToken: data?.accessToken });
                  subject.next()
                  subject.complete()
                }).catch((error) => this.errorRegisterRedirectCallback(error));

            } else this.errorRegisterRedirectCallback(error)
          });

          this.msalService.acquireTokenSilent(casApiScope).toPromise().then(data => {
            this.tokenStore.update({ casApiToken: data?.accessToken });
            subject.next()
            subject.complete()
          }).catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {

              this.msalService.acquireTokenPopup(casApiScope)
                .toPromise()
                .then((data) => {
                  this.tokenStore.update({ casApiToken: data?.accessToken });
                  subject.next()
                  subject.complete()
                }).catch((error) => this.errorRegisterRedirectCallback(error));

            } else this.errorRegisterRedirectCallback(error)
          });

          this.msalService.acquireTokenSilent(ussApiScope).toPromise().then(data => {
            this.tokenStore.update({ ussApiToken: data?.accessToken });
            subject.next()
            subject.complete()
          }).catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {

              this.msalService.acquireTokenPopup(ussApiScope)
                .toPromise()
                .then((data) => {
                  this.tokenStore.update({ ussApiToken: data?.accessToken });
                  subject.next()
                  subject.complete()
                }).catch((error) => this.errorRegisterRedirectCallback(error));

            } else this.errorRegisterRedirectCallback(error)
          });

          this.msalService.acquireTokenSilent(catApiScope).toPromise().then(data => {
            this.tokenStore.update({ catApiToken: data?.accessToken });
            subject.next()
            subject.complete()
          }).catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {

              this.msalService.acquireTokenPopup(catApiScope)
                .toPromise()
                .then((data) => {
                  this.tokenStore.update({ catApiToken: data?.accessToken });
                  subject.next()
                  subject.complete()
                }).catch((error) => this.errorRegisterRedirectCallback(error));

            } else this.errorRegisterRedirectCallback(error)
          });
      }).catch((error) => this.errorRegisterRedirectCallback(error));

    return subject;
  }
}
