export class MonthlyCompliance {
    public month: string;
    public monthName: string;
    public year: string;
    public compliance: string;
    public jobCount: number;

    constructor(data?: Partial<MonthlyCompliance>) {
        Object.assign(this, data);
    }
}
