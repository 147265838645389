import { Component } from '@angular/core';

@Component({
  selector: 'pcp-empty-cpa-template',
  templateUrl: './empty-cpa-template.component.html',
  styleUrls: ['./empty-cpa-template.component.scss']
})
export class EmptyCpaTemplateComponent {

}
