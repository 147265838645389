<div class="wrapper-target">
  <div class="wrapper-section">
    <section class="content">
      <div class="container-image">
        <img *ngIf="!user?.avatar" src="assets/images/user-avatar-placeholder.png" alt="user">
        <img *ngIf="user?.avatar" [src]="user.avatar" alt="user">
      </div>
      <h1 class="user-name">{{user?.name}}</h1>
      <span class="job-code">{{user?.jobCode}}</span>
    </section>
  </div>

  <!-- <div class="target-div">
    <pcp-target-status class="target-status"></pcp-target-status>
  </div> -->
</div>
