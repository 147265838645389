import { Component, Input, OnInit } from '@angular/core';
import { ProficiencyLevelType } from '~/shared/enums/proficiency-level-type.enum';
import { RequirementType } from '~/shared/enums/requirement-type.enum';
import { PromotionCompetencyElement } from '~/shared/models/competency-element.model';
import { ProficiencyLevel } from '~/shared/models/proficiency-level.model';
import { PromotionRequirement } from '~/shared/models/promotion-requirement.model';

@Component({
  selector: 'pcp-display-proficiency-level',
  templateUrl: './display-proficiency-level.component.html',
  styleUrls: ['./display-proficiency-level.component.scss']
})
export class DisplayProficiencyLevelComponent implements OnInit {
  @Input() public requirementType: string;
  @Input() public competencyElement: PromotionCompetencyElement;
  @Input() public inputPlTarget: string;
  @Input() public plTarget: ProficiencyLevel;
  @Input() public currentProficiencyLevel: ProficiencyLevel;
  public status = false;

  public pl: ProficiencyLevel;
  public requirementLevel: ProficiencyLevel;
  public levels: Array<{ name, keyEnum }> = [
    { name: ProficiencyLevelType.basic.valueOf(), keyEnum: 81 },
    { name: ProficiencyLevelType.intermediate.valueOf(), keyEnum: 82 },
    { name: ProficiencyLevelType.advanced.valueOf(), keyEnum: 83 },
    { name: ProficiencyLevelType.expert.valueOf(), keyEnum: 84 },
  ]

  public constructor() {
    //
  }

  private setProficiencyLevel() {
    this.pl = new ProficiencyLevel();
    this.pl.setByName(this.competencyElement.employeeProficiencyLevel);
    this.pl.setNameById(this.pl.id);
  }

  private setProficiencyLevelTargetComplete() {
    this.plTarget = new ProficiencyLevel();
    this.plTarget.setByName(this.inputPlTarget);
    this.plTarget.setNameById(this.plTarget.id);
  }

  private setProficiencyLevelTargetSpecific() {
    this.plTarget = new ProficiencyLevel();
    this.plTarget.setByName(this.competencyElement.requiredProficiencyLevel);
    this.plTarget.setNameById(this.plTarget.id);
  }

  private setStatusSpecific() {
    if (this.competencyElement.ceResult !== 'Fail') {
      this.status = true;
    }
  }

  private verifyEmployeeProficiencyLevel() {
    if (this.competencyElement.employeeProficiencyLevel === undefined || this.competencyElement.employeeProficiencyLevel === null) {
      this.competencyElement.employeeProficiencyLevel = 'None';
    }
  }

  public isRequirementTypePoints() {
    return this.requirementType == RequirementType.points.valueOf();
  }
  public isRequirementTypeComplete() {
    return this.requirementType == RequirementType.complete.valueOf();
  }
  public isRequirementTypeSpecific() {
    return this.requirementType == RequirementType.specific.valueOf();
  }

  public ngOnInit(): void {
    if (this.competencyElement === undefined) {
      return;
    }

    if (this.requirementType) {
      this.verifyEmployeeProficiencyLevel();
      this.setProficiencyLevel();

      if (this.isRequirementTypeComplete()) {
        this.setProficiencyLevelTargetComplete();
      }

      if (this.isRequirementTypeSpecific()) {
        this.setProficiencyLevelTargetSpecific();
        this.setStatusSpecific();
      }
    } else {
      this.pl = this.currentProficiencyLevel;
    }
  }

  public levelPointsLabel(id: number): string {
    switch(id) {
      case 81:
        return this.competencyElement.points.find(
          (x) => x.proficiencyLevelType === ProficiencyLevelType.basic)?.cePoints.toString() ?? '';
      case 82:
        return this.competencyElement.points.find(
          (x) => x.proficiencyLevelType === ProficiencyLevelType.intermediate)?.cePoints.toString() ?? '';
      case 83:
        return this.competencyElement.points.find(
          (x) => x.proficiencyLevelType === ProficiencyLevelType.advanced)?.cePoints.toString() ?? '';
      case 84:
        return this.competencyElement.points.find(
          (x) => x.proficiencyLevelType === ProficiencyLevelType.expert)?.cePoints.toString() ?? '';
    }
  }
}
