<section class="container container-dialog">

  <header>
    <h1>{{data.headerMessage}}</h1>
    <button class="button-close" (click)="close()">
      <img src="assets/icons/close-dialog.svg" alt="close">
    </button>
  </header>

  <div class="container-message">
    <h3>{{data.message}}</h3>
    <h5>{{data.submessage}}</h5>
    <h3>{{data.name}}</h3>
  </div>


  <div class="div-buttons">
    <button (click)="postPone()" mat-stroked-button class="button-cancel" *ngIf="!data.hideCancelButton">
      {{ textCancelButton }}
    </button>
    <button (click)="confirm()" [ngStyle]="{ 'background-color': (data.color ? data.color : '#0014DC') }"
    mat-flat-button color="accent" class="button-confirmation">
      {{data.textConfirmButton}}
    </button>
  </div>


</section>
