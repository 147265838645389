import { Component, Input, OnInit } from '@angular/core';
import { Discipline } from 'src/app/shared/models/disciplines.model';
import { ElementDetailService } from '~/core/services/element-detail.service';
import { getDefaultClassProgress } from 'src/app/shared/utils/class-progress';
import { isMobileSize } from 'src/app/shared/utils/navigator';

@Component({
  selector: 'app-targets-element-list',
  templateUrl: './targets-element-list.component.html',
  styleUrls: ['./targets-element-list.component.scss']
})
export class TargetsElementListComponent implements OnInit {

  @Input() public elements: Array<any>;
  @Input() public isHome = false;
  @Input() public expanded: boolean;
  @Input() public groupingType: string;
  @Input() public elementSelected: Discipline;
  public onDetail: any;
  public panelOpenState: boolean;

  public loadedElements: Array<any>;

  public isRequirementTypePoints(element: any) {
    return element.completedPercent !== '100%';
  }
  public isElementTypeComplete(element: any) {
    return element.completedPercent === '100%';
  }

  constructor(private elementDetailService: ElementDetailService) {
    //
  }

  ngOnInit(): void {
    this.loadedElements = [];
    if (this.elements) {
      this.loadedElements = JSON.parse(JSON.stringify(this.elements));
      this.loadedElements.forEach(el => {
        el.completedPercent = parseInt(el.completedPercent,10);
        if (!el.completedPercent) {
          el.completedPercent = 0;
        }

        if (el.competencyElements?.length > 0) {
          el.competencyElements.sort(function (a, b) {
            if (a.name < b.name) {
              return -1;
            }
            if ( a.name > b.name) {
              return 1;
            }
            return 0;
          });
        }
      });
    }
  }

  public completedPercent(percent: string): number{
    let integer = parseInt(percent, 10);
    if (!integer) {
      integer = 0;
    }

    return integer / 100;
  }
  public getClassProgressByValue(element: any): string {
    const percentage = this.completedPercent(element.completedPercent)
    return getDefaultClassProgress(percentage);
  }

  get isMobileSize() {
    return isMobileSize();
  }

  public showElementsDetail(element: any, requirement: any) {
    if (this.onDetail?.ceId === element.ceId) {
      this.onDetail = undefined;
      this.elementDetailService.clear();
      return;
    }

    this.onDetail = element;

    if (!this.isMobileSize) {
      element.id = element.ceId
      this.elementDetailService.clearDataTypePCE();
      this.elementDetailService.setValue(element);
    }
  }

  numberToEqualOrHigherThanZero(numberToVerify: number): number {
    let result = 0;

    if (numberToVerify > 0) {
      result = numberToVerify;
    }

    return result;
  }

  pointAchievedPercentage(requirement: any): number {
    const pointsAchieved = requirement?.pointsAchieved ?? 0;

    const percentage = (pointsAchieved * 100) / requirement.requiredPoints;
    return percentage;
  }

}
