<!-- <section class="container-dialog" style="width: 90px; height: 190px;">

  <!-- <header>
    <h3>Feedback</h3>

    
  </header> -->
<!-- <div class="container-fluid"> -->
  <!-- <a href="https://slbstaging.service-now.com/esc?id=sc_cat_item&table=sc_cat_item&sys_id=60e3eb0d1b29b910e599dac7bd4bcb25" target="_blank">ESM FeedBack</a>
  <br>
  <a href=" https://slbstaging.service-now.com/esc?id=sc_cat_item&table=sc_cat_item&sys_id=60e3eb0d1b29b910e599dac7bd4bcb25" target="_blank">SLB</a>
</div>
<button class="button">
  <span>
    <a href="">Help</a>
  </span>

  <ul class="helpWrapper">
    <li class="Feedback"><a href="https://www.youtube.com">
      <span >
      <svg width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M20 34C12.28 34 6 27.72 6 20C6 12.28 12.28 6 20 6C27.72 6 34 12.28 34 20C34 27.72 27.72 34 20 34ZM20 8.4C13.56 8.4 8.36 13.6 8.36 20C8.36 26.4 13.56 31.64 20 31.64C26.44 31.64 31.64 26.44 31.64 20C31.6 13.6 26.4 8.4 20 8.4Z"
          fill="white" />
        <path
          d="M19.6401 23.8799C19.0001 23.8799 18.4401 23.3599 18.4401 22.6799C18.4401 20.84 19.6401 19.2399 21.3601 18.7199C22.1202 18.48 22.6401 17.7999 22.6001 16.9999C22.6001 15.5599 21.4001 14.3599 19.9601 14.3599C18.5202 14.3599 17.3202 15.5599 17.3202 16.9999C17.3202 17.6398 16.8001 18.1999 16.1202 18.1999C15.4802 18.1999 14.9202 17.6798 14.9202 16.9999C15.0001 14.24 17.2401 11.9999 20.0002 11.9999C22.7602 11.9999 25.0002 14.2398 25.0002 16.9999C25.0002 18.8398 23.8002 20.4399 22.0801 20.9599C21.3201 21.1999 20.8002 21.8799 20.8401 22.6799C20.8401 23.3199 20.2801 23.8798 19.6401 23.8798L19.6401 23.8799Z"
          fill="white" />
        <path
          d="M21.0799 26.36C21.0799 27.1553 20.4352 27.8 19.6399 27.8C18.8446 27.8 18.2 27.1553 18.2 26.36C18.2 25.5647 18.8446 24.92 19.6399 24.92C20.4352 24.92 21.0799 25.5647 21.0799 26.36Z"
          fill="white" />
      </svg>
    </span>Feedback</a></li>
    
    <li class="Faq">
      <a href="https://www.youtube.com/watch?v=2105QQ3og6k">
        <span>
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M6 11.4003L7.5 12.5175V27.75H6V30H7.5H28.5H30V27.75H28.5V13.5938L24.0469 9H23.3509C21.4546 4.25902 18.6821 1.71141 15.0393 1.58402C14.7712 1.57465 12.223 1.57465 7.20639 1.58334L6 1.8831V0H0V2.25H3.75V11.25H0V13.5H6V11.4003ZM26.25 27.75V14.5053L23.0943 11.25H18.7378C18.9795 12.7042 18.3338 13.964 16.9407 14.7829C15.3456 15.7204 12.9424 15.9019 11.5674 15.1739C11.0239 14.8862 10.419 14.5253 9.75 14.0899V27.75H26.25ZM6 8.63768V4.16304L7.35181 3.83309C12.1435 3.82488 14.7406 3.82495 14.9607 3.83265C17.3841 3.9174 19.3649 5.58017 20.9078 9H17.726C17.6631 8.91294 17.5973 8.82565 17.5286 8.73819C16.7299 7.72107 15.3 7.26462 13.2618 7.23417L12.073 5.83815L10.36 7.29694L12.2324 9.49571L12.7654 9.48527C14.4537 9.45219 15.4419 9.7239 15.7591 10.1278C16.869 11.5412 16.751 12.2844 15.8005 12.8431C14.8436 13.4056 13.2617 13.5251 12.6201 13.1854C11.7104 12.7038 10.5644 11.9649 9.19057 10.9713L6 8.63768ZM12.0938 18H24V20.25H12.0938V18ZM24 22.5H12.0938V24.75H24V22.5Z"
              fill="white" />
          </svg>
        </span>FAQ</a>
    </li>
    

  </ul>

</button>
  

  

</section> --> -->
