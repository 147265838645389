<section class="container full-width">

  <span class="filter-label">Filtered By</span>

  <div
    *ngIf="showFilterSearch"
    class="form-input-search"
    [class.filtred]="search.value"
    [class.expandSearch]="!(showFilterSelection)">

     <div class="container-input" (click)="onSearchFocus(inputSearch)">
      <input #inputSearch type="text" placeholder="Search" [formControl]="search" class="input-search">
     </div>

    <mat-icon (click)="onSearchFocus(inputSearch)" class="icon-search" matSuffix>search</mat-icon>

    <mat-icon (click)="onClickClearSearch()" class="icon-clear" matSuffix>close</mat-icon>

  </div>

  <div *ngIf="showFilterSelection " class="filter-input">

    <span *ngFor="let filter of filters.selects">

      <pcp-selected-filter  [labels]="labels" [filter]="filter" (removeClicked)="removeSelectedFilter($event);">
      </pcp-selected-filter>

    </span>

    <pcp-select-filter [filteredDataSource]="dataSource" [filters]="filters"
      [labelKeys]="labelKeys" [labels]="labels" (onKeyValueSelect)="keyValueSelected($event);"
      >
    </pcp-select-filter>

  </div>

</section>
