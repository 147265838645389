import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Configuration } from '~/shared/models/configuration.model';
import { Observable } from 'rxjs/internal/Observable';
import { CompetencyApiBaseService } from './base/competency-api-base.service';
// import { TargetDto } from './dtos/target.dto';
import { Target } from '../models/target.model';
import { TargetStatus } from '../models/target-status.model';
import { TargetStore } from '~/store/target/target.store';
import { AuthService } from '~/core/services/auth.service';
import { map } from 'rxjs/internal/operators/map';
import { catchError, tap, timeout, retry } from 'rxjs/operators';
import { TargetStatusStore } from '~/store/target-status/target-status.store';
import { TokenQuery } from '~/store/token/token.query';
import { TargetV2 } from '../models/targetV2.model';
import { Subject } from 'rxjs';
import { TargetV2Store } from '~/store/targetV2/targetV2.store';

@Injectable({ providedIn: 'root' })
export class TargetService extends CompetencyApiBaseService<any> {

  constructor(
    httpClient: HttpClient,
    config: Configuration,
    token: TokenQuery,
    private targetStore: TargetStore,
    private targetStatusStore: TargetStatusStore,
    private authService: AuthService,
    private targetV2Store: TargetV2Store
  ) {
    super(httpClient, config, token);
    this.endpoint = 'competency_reporting/my-targets';
  }

  public getTargetsV2(): Observable<Array<TargetV2>> {

    const subject = new Subject<Array<TargetV2>>();
    const ginNumber = this.authService.getGinNumber();

    this.getPatSingleCustom(`${this.config.newApiUrl}/targets/get?GinNumber=${ginNumber}`, true)
      .pipe(
        catchError((error) => {
          this.targetV2Store.setLoading(false);
          return null;
        }),
        tap((result: any) => {
          this.targetV2Store.update({targetV2: result});
          this.targetV2Store.setLoading(false);
          subject.next(result);
        }),
        timeout(30000),
        retry(1)
      ).subscribe();

    return subject;
  }
}
