import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { interceptorsProvider } from './core/providers/interceptors.provider';
import { ModulesModule } from './modules/modules.module';
import { SharedModule } from './shared/shared.module';
import { GlobalErrorHandler } from './shared/utils/error-handler';
import { ImpersonateFlowLibraryModule } from '@des-cms/impersonate-library';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MsalModule,
    ModulesModule,
    SharedModule,
    ImpersonateFlowLibraryModule
  ],
  providers: [
    MsalService,
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    interceptorsProvider,
    MsalGuard,
  ],
  bootstrap: [
    AppComponent, MsalRedirectComponent
  ]
})
export class AppModule { }
