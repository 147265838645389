import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  devEnvironment: boolean;

  get isDev(): boolean {
    return this.devEnvironment;
  }

  set setEnvironment(element: any) {
    this.devEnvironment = element;
  }
}
