import { Component, Input } from '@angular/core';

@Component({
  selector: 'pcp-cert-card',
  templateUrl: './cert-card.component.html',
  styleUrls: ['./cert-card.component.scss']
})
export class CertCardComponent {
  @Input() certs: Array<any>;

  openLinkCerts(element: any) {
    window.open(element.certLink,'_blank');
  }
}
