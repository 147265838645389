import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { RequirementType } from '~/shared/enums/requirement-type.enum';
import { PromotionGroup } from '~/shared/models/promotion-group.model';
import { PromotionRequirement } from '~/shared/models/promotion-requirement.model';
import { getDefaultClassProgress } from '~/shared/utils/class-progress';

@Component({
  selector: 'pcp-promotion-group',
  templateUrl: './promotion-group.component.html',
  styleUrls: ['./promotion-group.component.scss']
})
export class PromotionGroupComponent implements OnInit {

  @Input()
  public isHome = false;

  @Input()
  public isPromotion = false;

  @Input()
  public group: PromotionGroup;

  @Output()
  public groupClick = new EventEmitter<PromotionGroup>();
  public percentageValue = 0;

  public isRequirementTypePoints(requirement: PromotionRequirement) {
    return requirement.requirementType === RequirementType.points.valueOf();
  }
  public isRequirementTypeComplete(requirement: PromotionRequirement) {
    return requirement.requirementType === RequirementType.complete.valueOf();
  }
  public isRequirementTypeSpecific(requirement: PromotionRequirement) {
    return requirement.requirementType === RequirementType.specific.valueOf();
  }

  ngOnInit() {
    if (!this.group) {
      return;
    }

    if (this.isCompleted()) {
      const oneHundredPercent = 100;
      this.percentageValue = oneHundredPercent;
      return;
    }

    if (this.group.requirements?.length > 0) {
      if (this.group.groupingType.toLowerCase() === 'or') {
        const percentages = new Array<number>();
        this.group.requirements.forEach(req => {
          if (this.isRequirementTypeComplete(req)) {
            percentages.push(this.achievedCeCountDividedPerRequiredCountPercentage(req));
          }
          else if (this.isRequirementTypePoints(req)){
            percentages.push(this.pointAchievedPercentage(req));
          }
          else if (this.isRequirementTypeSpecific(req)) {
            percentages.push(this.achievedCeCountDividedPerCeCountPercentage(req));
          }
        });

        this.percentageValue = Math.round(Math.max(...percentages));
      } else {
        const numeratorAndDenominator = this.calculateNumeratorAndDenominator();
        const numerator = numeratorAndDenominator.numerator * 100;
        const denominator = numeratorAndDenominator.denominator > 0 ?
          numeratorAndDenominator.denominator :
          1;

        this.percentageValue = Math.round(numerator/denominator);
      }
    }
  }

  public viewGroup(): void {
    this.groupClick.emit(this.group);
  }

  public getDefaultClassProgressComponent() {
    return getDefaultClassProgress(this.percentageValue);
  }

  isCompleted() {
    return this.group?.groupResult.toLowerCase() !== 'fail';
  }

  labelGroupProgress() {
    return  this.isCompleted() ? 'Completed' : 'In Progress';
  }

  calculateNumeratorAndDenominator() {
    let numerator = 0;
    let denominator = 0;
    let numeratorProperty = '';
    let denominatorProperty = '';

    this.group.requirements.forEach(requirement => {
      numeratorProperty = this.setNumeratorProperty(requirement);
      denominatorProperty = this.setDenominatorProperty(requirement);

      if(!requirement[numeratorProperty] || requirement[numeratorProperty] < 0) {
        requirement[numeratorProperty] = 0;
      }

      if (!requirement[denominatorProperty]) {
        requirement[denominatorProperty] = 0;
      }

      numerator += requirement[numeratorProperty] <= requirement[denominatorProperty] ?
      requirement[numeratorProperty] :
      requirement[denominatorProperty];

      denominator += requirement[denominatorProperty];
    });

    return {
      numerator,
      denominator
    };
  }

  setNumeratorProperty(requirement: PromotionRequirement): string {
    if (this.isRequirementTypeComplete(requirement) || this.isRequirementTypeSpecific(requirement)) {
      return 'achievedCeCount';
    } else {
      return 'pointsAchieved';
    }
  }

  setDenominatorProperty(requirement: PromotionRequirement): string {
    if (this.isRequirementTypeComplete(requirement)) {
      return 'requiredCount';
    }
    else if (this.isRequirementTypePoints(requirement)) {
      return 'requiredPoints';
    }
    else if (this.isRequirementTypeSpecific(requirement)) {
      return 'cecount';
    }
  }

  pointAchievedPercentage(requirement: PromotionRequirement): number {
    const pointsAchieved = requirement?.pointsAchieved ?? 0;
    let percentage = (pointsAchieved * 100) / requirement.requiredPoints;
    if (percentage > 100) {
      percentage = 100;
    }

    return percentage;
  }

  achievedCeCountDividedPerRequiredCountPercentage(requirement: PromotionRequirement): number {
    const numerator = ((requirement.achievedCeCount ?? 0) * 100);
    const denominator = (!requirement.requiredCount || requirement.requiredCount < 1 ? 1 : requirement.requiredCount);
    let percentage = numerator/denominator;
    if (percentage > 100) {
      percentage = 100;
    }

    return percentage;
  }

  achievedCeCountDividedPerCeCountPercentage(requirement: PromotionRequirement): number {
    if(!requirement.achievedCeCount || requirement.achievedCeCount < 0) {
      requirement.achievedCeCount = 0;
    }

    const numerator = ((requirement.achievedCeCount ?? 0) * 100);
    const denominator = (!requirement.cecount || requirement.cecount < 1 ? 1 : requirement.cecount);
    let percentage = numerator/denominator;
    if (percentage > 100) {
      percentage = 100;
    }

    return percentage;
  }

}
