import { MatDialog } from '@angular/material/dialog';
import { UserQuery } from './../../../../store/user/user.query';
import { Component, Input, OnInit } from '@angular/core';
import { Assessor, ElementDetails, Training } from '~/shared/models/competency-details.model';
import { Configuration } from '~/shared/models/configuration.model';
import { AssessmentService } from '~/shared/services/assessment.service';
import { AssessmentDto } from '~/shared/services/dtos/assessment.dto';
import { SpinnerFeedbackDialogComponent } from '~/shared/components/spinner-feedback-dialog/spinner-feedback-dialog.component';
import { FeedbackResultType } from '~/shared/enums/feedback-result-type.enum';
import { DialogConfirmationComponent } from '~/shared/components/dialog-confirmation/dialog-confirmation.component';
import { ResultResponseType } from '~/shared/enums/result-response-type.enum';
import { ErrorResponse } from '~/shared/services/dtos/error.dto';
import { isMobileSize } from '~/shared/utils/navigator';

@Component({
  selector: 'pcp-element-training',
  templateUrl: './element-training.component.html',
  styleUrls: ['./element-training.component.scss']
})
export class ElementTrainingComponent implements OnInit {
  @Input() competencyDetail: ElementDetails;
  @Input() pathwayType: string;
  @Input() pathwayCompleted: boolean;
  @Input() proficiencyLevel: string;
  @Input() ceInfo: { id: number, name: string };
  @Input() training: Training;
  @Input() hasNoTemplates: boolean;
  @Input() isAssessmentAllowed: boolean;
  @Input() quantityRequired: number;
  @Input() quantityCompleted: number;

  public isLoading = true;
  public ilearnUrlPrefix: string;
  certs: Array<any>;
  sources: Array<any>;
  noTrainingData: boolean = false;
  needMoreExp: boolean = false;
  assessors: Array<Assessor>;
  filteredAssessors: Array<Assessor>;
  needAssessors: boolean = false;
  hasAssessorSelectedForAssessment: boolean = false;

  public constructor(
    private config: Configuration,
    private assessmentService: AssessmentService,
    private userQuery: UserQuery,
    private dialog: MatDialog
  ) {
  }

  public ngOnInit(): void {
    this.ilearnUrlPrefix = this.config.ilearnUrlPrefix;
    this.isLoading = false;
    this.sources = this.competencyDetail?.sources;
    this.certs = this.competencyDetail?.certs;
    this.assessors = this.competencyDetail?.assessors;
    this.filteredAssessors = this.assessors;
    this.verifyPathwayType(this.pathwayType);

    this.checkAssessorsForOngoingAssessment();
  }

  checkAssessorsForOngoingAssessment() {
    this.hasAssessorSelectedForAssessment = null;
    if(this.competencyDetail?.assessmentDetails?.assessorGin && this.assessors?.length) {
      this.hasAssessorSelectedForAssessment = this.assessors.some(assessor => {
        if(assessor?.gin) {
          return assessor.gin.toString() === this.competencyDetail.assessmentDetails.assessorGin.toString();
        }
      });
    }
  }

  isAssessorSelected(assessorGin: number) {
    return assessorGin?.toString() === this.competencyDetail?.assessmentDetails?.assessorGin?.toString();
  }

  checkIfComponentShouldUseMobileSize() {
    return isMobileSize();
  }

  getAssessmentStatus(assessorGin: number) {
    const statusToReturn = this.competencyDetail?.assessmentDetails?.status;

    if (statusToReturn && this.isAssessorSelected(assessorGin)) {
      return statusToReturn;
    }
    return null;
  }

  verifyPathwayType(element: string) {
    if (!element) {
      return;
    }

    if (element === 'JE' ||
      element === 'RJE' ||
      element === 'ME' ||
      element === 'RME' ||
      element === 'PLE' ||
      element === 'AC') {
      this.needMoreExp = true;
    }
    else if (element === 'T') {
      this.noTrainingData = true;
    }
    else if (element === 'CPA' ||
      element === 'CTI') {
      this.needAssessors = true;
    }
  }

  manageAssessorAssessmentCPA(assessor: Assessor) {
    if (this.isAssessmentAllowed === false) {
      return;
    }

    if (assessor.gin.toString() === this.competencyDetail?.assessmentDetails?.assessorGin.toString()) {
      this.deleteAssessorAssessmentCPA(assessor);
    } else {
      this.addAssessorAssessmentCPA(assessor);
    }
  }

  addAssessorAssessmentCPA(assessor: Assessor) {
    const user = this.userQuery.getValue().user;
    const assessment: AssessmentDto = {
      assesseeGin: user.ginNumber.toString(),
      assesseeName: user.name,
      ceId: this.ceInfo.id,
      ceName: this.ceInfo.name,
      pl: this.proficiencyLevel,
      assessorName: assessor.name,
      assessorGin: assessor.gin.toString(),
      source: '1'
    };

    const dialogConfirm = this.dialog.open(DialogConfirmationComponent, {
      data: {
        headerMessage: 'Confirm',
        message: `Confirm the request to the assessor ${assessor.name}?`,
        textConfirmButton: 'CONFIRM',
        textCancelButton: 'CANCEL'
      }
    });

    dialogConfirm.afterClosed().subscribe(response => {
      if (response) {
        const dialog = this.dialog.open(SpinnerFeedbackDialogComponent);

        dialog.afterOpened().subscribe(opened => {
          this.assessmentService.addAssessment(assessment).subscribe(response => {
            if (response?.result === ResultResponseType.success) {
              dialog.componentInstance.updateFeedbackResult(false, FeedbackResultType.success, "Success creating the assessment.");

              this.assessmentService.getAssessment(parseInt(assessment.assesseeGin, 10), assessment.ceId, assessment.pl)
                .subscribe((assessmentDetails) => {
                  this.isLoading = false;
                  this.competencyDetail.assessmentDetails = assessmentDetails as any;
                  this.checkAssessorsForOngoingAssessment();
                });
            } else {
              dialog.componentInstance.updateFeedbackResult(false, FeedbackResultType.error, (response.error as ErrorResponse).error.message);
            }
          });
        });
      }
    });
  }

  deleteAssessorAssessmentCPA(assessor: Assessor) {
    const dialogCancelAssessment = this.dialog.open(DialogConfirmationComponent, {
      data: {
        headerMessage: 'Cancel Assessment',
        message: `Are you sure you would like to cancel this assessment request to the assessor ${assessor.name}?`,
        textConfirmButton: 'CONFIRM',
        textCancelButton: 'CANCEL'
      }
    });

    dialogCancelAssessment.afterClosed().subscribe(response => {
      const assessmentId = this.competencyDetail.assessmentDetails.id;

      if (response) {
        const dialog = this.dialog.open(SpinnerFeedbackDialogComponent);

        dialog.afterOpened().subscribe(opened => {
          this.assessmentService.removeAssessment(assessmentId).subscribe(response => {
            if (response?.result === ResultResponseType.success) {
              dialog.componentInstance.updateFeedbackResult(false, FeedbackResultType.success, "Assessment cancelled successfully.");
              this.competencyDetail.assessmentDetails = null;
              this.checkAssessorsForOngoingAssessment();
            } else {
              dialog.componentInstance.updateFeedbackResult(false, FeedbackResultType.error, (response.error as ErrorResponse).error.message);
            }
          });
        });
      }
    });
  }

  showElementCompletedTemplate() {
    return (this.pathwayCompleted && (!this.sources || this.sources?.length < 1) && this.pathwayType !== 'T') ||
      this.sources?.length > 0
  }

  applyAssessorsFilters(data: Assessor[]) {
    this.filteredAssessors = data;
  }
}
