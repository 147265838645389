import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, firstValueFrom, lastValueFrom } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { catchError, tap } from 'rxjs/operators';
import { UserStore } from '~/store/user/user.store';
import { UserQuery } from '~/store/user/user.query';
import { Configuration } from '../models/configuration.model';
import { User } from '../models/user.model';
import { CompetencyApiBaseService } from './base/competency-api-base.service';
import { TokenQuery } from '~/store/token/token.query';

@Injectable({
  providedIn: 'root'
})
export class UserGraphService  extends CompetencyApiBaseService<any>{

   private graphEndpoint = '';
   private user: any;
   private userAvatar: string;
   user$: Observable<User>;

  constructor(
    httpClient: HttpClient,
    config: Configuration,
    token: TokenQuery,
    private userStore: UserStore,
    private userQuery: UserQuery
  ) {
    super(httpClient, config, token);
    this.graphEndpoint = `${this.config.graphApi}/users`;
  }

  private updateUserPhotoOnStore(avatar: string): void {
    let user;
    user = Object.assign({}, this.user, { avatar });
    this.userStore.update({ user });
  }

  public getUser(gin: number, isDev: boolean) {
    const select = 'extension_8b4e685f580748b59864d62c2e7fcfad_msDS_cloudExtensionAttribute12,displayName,extension_8b4e685f580748b59864d62c2e7fcfad_employeeNumber,jobTitle';
    const url = `${this.config.graphApi}/me?$select=${select}`;

    if (isDev) {
      this.httpClient.get(url).pipe(
        tap((result: any) => {
          if (result) {
            this.user = new User({
              ginNumber: gin,
              name: result.displayName,
              jobCode: result.jobTitle
            });
            let user;
            const avatar = this.userQuery.getValue().user?.avatar;
            if (avatar) {
              user = Object.assign({}, this.user, { avatar });
            } else {
              user = this.user;
            }
            this.userStore.update({ user });
          }
        })
      ).subscribe();
    } else {
      this.httpClient.get(url).pipe(
        tap((result: any) => {
          if (result) {
            this.user = new User({
              ginNumber: result.extension_8b4e685f580748b59864d62c2e7fcfad_employeeNumber,
              name: result.displayName,
              jobCode: result.jobTitle
            });
            let user;
            const avatar = this.userQuery.getValue().user?.avatar;
            if (avatar) {
              user = Object.assign({}, this.user, { avatar });
            } else {
              user = this.user;
            }
            this.userStore.update({ user });
          }
        })
      ).subscribe();
    }
  }

  public getGinNumber(email: string): Observable<number> {

    const filter = `userPrincipalName eq '${email}'`;
    const projection = 'extension_8b4e685f580748b59864d62c2e7fcfad_employeeNumber';

    const url = `${this.graphEndpoint}?$filter=${filter} &$Select=${projection}`;

    return this.httpClient.get(url)
      .pipe(map((result: any) => parseInt(result.value[0] ? result.value[0][projection] : null, null)));
  }

  public getPhoto(email: string): Observable<string> {

    const subject = new Subject<string>();
    this.httpClient.get(`${this.graphEndpoint}/${email}/photo/$value`, { responseType: 'blob' })
      .pipe(
        tap((result: any) => {
          if (result) {
            const reader = new FileReader();
            reader.onloadend = () => {
              this.compressImage(reader.result as string,  85, 85).then(compressed => {
                this.userAvatar = compressed.toString();
                this.updateUserPhotoOnStore(this.userAvatar as string)
                subject.next(this.userAvatar as string);
                subject.complete();
              });
            }
            reader.readAsDataURL(result);
          } else {
            subject.next(null);
            subject.complete();
          }
        })).subscribe(() => {
          //
        }, (error) => {
          //
        });

    return subject;
  }

  compressImage(src, newX, newY) {
    return new Promise((res, rej) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        const elem = document.createElement('canvas');
        elem.width = newX;
        elem.height = newY;
        const ctx = elem.getContext('2d');
        ctx.drawImage(img, 0, 0, newX, newY);
        const data = ctx.canvas.toDataURL();
        res(data);
      }
      img.onerror = error => rej(error);
    })
  }
}
