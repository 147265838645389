<section>

  <header [ngClass]="{'is-mobile': checkIfComponentShouldUseMobileSize()}">
    <h2 mat-dialog-title>{{ pathwayRequirement }}</h2>
    <figure (click)="close()">
      <img src="assets/icons/close-dialog.svg" alt="close">
    </figure>
  </header>

  <mat-divider></mat-divider>

  <mat-dialog-content [ngClass]="{'is-mobile': checkIfComponentShouldUseMobileSize()}">

    <div class="panel-body" >
      <pcp-element-training *ngIf="!isLoading"
        [competencyDetail]="competencyDetail"
        [pathwayCompleted]="pathwayCompleted"
        [pathwayType]="pathwayType"
        [proficiencyLevel]="this.pl"
        [ceInfo]="{ id: this.elementId, name: this.competencyElementName}"
        [training]="training"
        [hasNoTemplates]="hasNoTemplates"
        [isAssessmentAllowed]="isAssessmentAllowed"
        [quantityCompleted]="quantityCompleted"
        [quantityRequired]="quantityRequired">
      </pcp-element-training>
    </div>

  </mat-dialog-content>
</section>
