export class UserSentimentSurvey {
   public isEligible: boolean;
   public urlToRedirect: string;
   public postponedDate: string;
   public userGinNumber: string;

   constructor(data?: Partial<UserSentimentSurvey>) {
      Object.assign(this, data);
   }
}
