import { Component, Input, OnChanges } from '@angular/core';
import { PcpRequirementsService } from './../../../../shared/services/pcp-requirements.service';
import { UserQuery } from '../../../../store/user/user.query';
import { PcpRequirements } from '../../../../shared/models/pcp-requirements.model';
import { ProficiencyLevel } from '../../../../shared/models/proficiency-level.model';
import { Competency } from '../../../../shared/models/competency-repoting.model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PcpHistoryService } from '~/shared/services/pcp-history.service';
import { PcpHistory } from '~/shared/models/pcp-history.model';
import { PromotionCompetencyElement } from '~/shared/models/competency-element.model';
import { User } from '~/shared/models/user.model';
import * as moment from 'moment-mini-ts';
import { FULL_WIDTH_DIALOG_MOBILE, MIN_WIDTH_DIALOG_MOBILE, SIZE_MIN_LG_SCREEN } from '~/shared/utils/navigator';
import { DialogDetailComponent } from '../dialog-detail/dialog-detail.component';
import { throwError } from 'rxjs';
import { AssessmentService } from '~/shared/services/assessment.service';
import { AssessmentStatus } from '~/shared/enums/assessment-status.enum';

@Component({
  selector: 'pcp-my-elements-detail',
  templateUrl: './element-detail.component.html',
  styleUrls: ['./element-detail.component.scss']
})
export class ElementDetailComponent implements OnChanges {
  @Input() public competencyElementName: string;
  @Input() public competencyElement: Competency;
  @Input() public promotionCompetencyElement: PromotionCompetencyElement;
  @Input() public inputPlTarget: string;
  @Input() public isRequirementTypeSpecific: boolean;


  public nextExpirationDate: string;
  public awardedOnDate: string;
  public basic: boolean;
  public pcpRequirements: PcpRequirements[];
  public nextLevelRequirements: PcpRequirements[];
  public currentLevelRequirements: PcpRequirements[];
  public proficiencyLevel: string;
  public isLoading: boolean = true;
  public hasContent: boolean = true;
  public panelOpenState = false;
  public panelOpenStatePL = true;
  public history: PcpHistory[];
  public pl: ProficiencyLevel;
  public plTarget: ProficiencyLevel;
  public warningCeId = 0;

  public constructor(
    private userQuery: UserQuery,
    private pcpRequirementsService: PcpRequirementsService,
    public dialog: MatDialog,
    private historyService: PcpHistoryService,
    private assessmentService: AssessmentService,
  ) {
  }

  public ngOnChanges(): void {
    const user = this.userQuery.getValue().user;

    if (!user) {
      return;
    }

    if (this.competencyElement !== undefined && this.competencyElement.id !== undefined) {
      this.getData(user, this.competencyElement.id, this.competencyElement.pl, this.competencyElement?.awardedOnDate);

      this.awardedOnDate = this.competencyElement?.awardedOnDate;
      this.nextExpirationDate = this.competencyElement?.expirationDate;
      this.pl = this.findProficiencyLevel(this.competencyElement?.pl);
      this.plTarget = this.findProficiencyLevel(this.competencyElement?.targetPL);
      this.competencyElementName = this.competencyElement.competencyElement;
      this.warningCeId = this.competencyElement.id;
    } else if (this.promotionCompetencyElement !== undefined && this.promotionCompetencyElement.ceId !== undefined) {
      if (this.isRequirementTypeSpecific === true) {
        this.inputPlTarget = this.promotionCompetencyElement.requiredProficiencyLevel;
        this.isRequirementTypeSpecific = false;
      }

      this.setProficiencyLevel();
      this.setProficiencyLevelTarget();
      this.getData(user, this.promotionCompetencyElement.ceId, this.pl?.id, this.promotionCompetencyElement.awardedOnDate);

      this.awardedOnDate = this.promotionCompetencyElement.awardedOnDate;
      this.competencyElementName = this.promotionCompetencyElement.name;
      this.warningCeId = this.promotionCompetencyElement.ceId;
    }
  }

  setProficiencyLevel() {
    this.pl = new ProficiencyLevel();
    if (this.promotionCompetencyElement?.employeeProficiencyLevel) {
      this.pl.setByName(this.promotionCompetencyElement.employeeProficiencyLevel);
      this.pl.setNameById(this.pl.id);
    }
  }

  setProficiencyLevelTarget() {
    this.plTarget = new ProficiencyLevel();
    if (this.inputPlTarget) {
      this.plTarget.setByName(this.inputPlTarget);
      this.plTarget.setNameById(this.plTarget.id);
    }
  }

  public findProficiencyLevel(level: number): ProficiencyLevel {
    const pl = new ProficiencyLevel();
    pl.id = level;
    pl.setNameById(level);

    return pl;
  }

  getData(user: User, ceId: number, currentPL: number, awardedOnDate: string) {
    if (ceId !== undefined) {
      if(currentPL === 80 && (awardedOnDate === undefined || awardedOnDate === null)) {
        this.pcpRequirementsService.getBasicRequirements(user.ginNumber, ceId).subscribe(
          (result) => {

            if(result.items) {
              this.hasContent = true;
              this.pcpRequirements = result.items;
              this.checkCPAProgressOnCAS(user.ginNumber);
            } else {
              this.hasContent = false;
              this.isLoading = false;
            }
          }
        );
      } else {
        this.pcpRequirementsService.getRequirements(user.ginNumber, ceId).subscribe(
          (result) => {
            if(result.items) {
              this.hasContent = true;
              this.pcpRequirements = result.items;
              this.checkCPAProgressOnCAS(user.ginNumber);
            } else {
              this.hasContent = false;
              this.isLoading = false;
            }
          },
          (error) => {
            throwError(error);
          }
        );
      }

      this.historyService.getPcpHistory(ceId).subscribe(result => {
        this.history = result
      });
    }
  }

  sendNeededForNextLevel(elements: any): Array<PcpRequirements>{
    return elements.filter((obj) => obj.pathway === "ReAttain" || obj.pathway === "Attain");
  }

  sendNeededToKeepCurrentLevel(elements: any): Array<PcpRequirements>{
    return elements.filter((obj) => obj.pathway === "Maintain");
  }

  checkCPAProgressOnCAS(userGinNumber: number) {
    if (this.pcpRequirements?.some(x => x.pathwayType?.toLocaleUpperCase() === "CPA" &&
        x.isAssessmentAllowed &&
        x.pathwayCompleted === false)) {
      this.pcpRequirements.forEach((pcpReq, index) => {
        if (pcpReq.pathwayType?.toLocaleUpperCase() === "CPA" &&
            pcpReq.isAssessmentAllowed &&
            pcpReq.pathwayCompleted === false) {
          this.assessmentService
            .getAssessment(Number(userGinNumber ?? 0), pcpReq.ceId, pcpReq.attemptedProficiency?.toString())
            .subscribe(response => {
              if (response && Number(AssessmentStatus[response.status ?? AssessmentStatus.Created]) === AssessmentStatus.Completed) {
                let newPcpReq = this.pcpRequirements[index];
                newPcpReq.pathwayCompleted = true;
                newPcpReq.quantityCompleted = newPcpReq.quantityRequired;
                this.pcpRequirements[index] = newPcpReq;
              }

              this.setLevelRequirements();
              this.isLoading = false;
            });
        }
      });
    } else {
      this.setLevelRequirements();
      this.isLoading = false;
    }
  }

  setLevelRequirements() {
    this.nextLevelRequirements = this.sendNeededForNextLevel(this.pcpRequirements);
    this.currentLevelRequirements = this.sendNeededToKeepCurrentLevel(this.pcpRequirements);
  }

  public openDialogDetail(event: {data: any, type: string, awardedOnDate: Date }): void {
    const dataDialog = {
      competencyElementName: this.competencyElementName,
      competencyElementId:event.data.ceId,
      pathwayRequirement: event.data.pathwayRequirement,
      typeData: event.type,
      pathway: event.data.pathway,
      pathwayType: event.data.pathwayType,
      pl: event.data.attemptedProficiency,
      fromDate: moment(event.awardedOnDate).format('YYYY-MM-DD'),
      origin: 'level-information',
      ...event.data
    }
    const sizeDialog: MatDialogConfig = {
      minWidth: MIN_WIDTH_DIALOG_MOBILE,
      data: dataDialog
    };

    if (window.innerWidth <= SIZE_MIN_LG_SCREEN) {
      sizeDialog.minWidth = FULL_WIDTH_DIALOG_MOBILE;
    }

    this.dialog.open(DialogDetailComponent, sizeDialog);
  }
}
