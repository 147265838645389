<section class="container">

  <div class="target-wrap">
    <img src="assets/icons/arrow.svg" (click)="navigateToHome()" class="target-image" alt="arrow" />
  </div>

  <pcp-dynamic-filter [dataSource]="competencyListData" [filtersDefault]="filtersDefault"
    (filtered)="applyFilter($event)"></pcp-dynamic-filter>

  <div *ngIf = "competencyList" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()"
    [infiniteScrollDisabled]="false" [scrollWindow]="false" class="container-wrapper">

    <div *ngFor="let d of competencyList"  class="container-detail rowSelectable"
      [class.rowOpacity]="showDetail && showDetail.competencyElement !== d.competencyElement"
      [class.rowSelected]="showDetail && showDetail.competencyElement === d.competencyElement">
      <pcp-card-item-list  [competency]="d"
        [proficiencyLevel]="findProficiencyLevel(d.pl)" [competencyTarget]="findProficiencyLevel(d.targetPL)"
        [hasIconStatus]="true" [status]="d.completedR" [daysRemaining]="d.expirationDate" [showDetail]="d.showDetail"
        [competencyUnits]="competencyList" (click)="showElementsDetail(d)">
      </pcp-card-item-list>

      <pcp-my-elements-detail *ngIf="isMobileSize && showDetail && showDetail.competencyElement === d.competencyElement"
        [competencyElement]="d">
      </pcp-my-elements-detail>
    </div>

  </div>
</section>
