import * as npm from '../../package.json';

export const environment = {
  //apiUrl: '#{apiUrl}#',   //de-comissioned api
  newApiUrl: '#{newApiUrl}#',
  apiUrlEsm: '#{apiUrlEsm}#',
  graphApi: '#{graphApi}#',
  apiKey: '#{apiKey}#',
  azureTenantId: '#{azureTenantId}#',
  azureClientId: '#{azureClientId}#',
  instrumentationKey: '#{instrumentationKey}#',
  ilearnUrlPrefix: '#{ilearnUrlPrefix}#',
  version: '#{version}#',
  isUnderMaintenance: '#{isUnderMaintenance}#',
  apiScope: '#{apiScope}#',
  newApiKey: '#{newApiKey}#',
  casApiKey: '#{casApiKey}#',
  casApiUrl: '#{casApiUrl}#',
  casApiScope: '#{casApiScope}#',
  catApiScope: '#{catApiScope}#',
  catApiKey: '#{catApiKey}#',
  catApiUrl: '#{catApiUrl}#',
  ussApiScope: '#{ussApiScope}#',
  ussApiKey: '#{ussApiKey}#',
  ussApiUrl: '#{ussApiUrl}#',
  apiUrlAdminTool: '#{apiUrlAdminTool}#',
  env: '#{env}#',
  esmApiScope: '#{esmApiScope}#',
  esmServiceOffering: '#{esmServiceOffering}#',
  linkEsmFeedback: "",
  azureImpersonationGroupId: '#{azureImpersonationGroupId}#',
  urlCreateTicketiLearn : '#{urlCreateTicketiLearn}#'
};
