import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PullToRefreshService } from '~/core/services/pull-to-refresh.service';
import { PageComponentBase } from '~/shared/components/page-component-base';
import { ProficiencyLevel } from '~/shared/models/proficiency-level.model';
import { User } from '~/shared/models/user.model';
import { UserQuery } from '~/store/user/user.query';
import { isMobileSize } from '~/shared/utils/navigator';
import { ElementDetailService } from '~/core/services/element-detail.service';
import { FilterMyElementsService } from '~/core/services/filter-my-elements.service';
import { sleep } from '~/shared/utils/sleep';
import { CompetencyReportingQuery } from '~/store/competency-reporting/competency-reporting.query';
import { Competency } from '~/shared/models/competency-repoting.model';

const MILLISECONDS_LOAD_LIST = 500;
@Component({
  selector: 'pcp-my-elements',
  templateUrl: './my-elements.component.html',
  styleUrls: ['./my-elements.component.scss']
})
export class MyElementsComponent extends PageComponentBase implements OnInit {

  public index = 0;
  public itemsPage = 20;
  public competencyList = new Array<Competency>();
  public competencyListData = new Array<Competency>();
  public competencyUnits = new Array<Competency>();
  public competencyUnitsData = new Array<Competency>();
  public user: User;
  public competencyElement: Competency;
  public competencyElementName: string;
  public showDetail: Competency;
  public awardedOnDate: Date;
  public nextExpirationDate: Date;
  public cardsClosed: boolean;
  public filtersDefault: { key, value }[] = [];
  public isLoading = false;
  public competencyNoneCount:number = 0

  constructor(
    pullToRefreshService: PullToRefreshService,
    private router: Router,
    private route: ActivatedRoute,
    private userQuery: UserQuery,
    private elementDetailService: ElementDetailService,
    private filterMyElementsService: FilterMyElementsService,
    private competencyReportingQuery: CompetencyReportingQuery
  ) {
    super(pullToRefreshService);
  }

  public ngOnInit(): void {
    this.userQuery.select('user')
      .subscribe((data) => {
        if (!data) return;
        this.user = data;
      })

      this.competencyReportingQuery.select()
      .subscribe(((data) => {
        let sortedData;
        if(data?.dto?.elements) {
          sortedData = this.orderDaysRemaining(data?.dto?.elements);
        }

        sortedData?.forEach((element) => {
          element.plName = this.findProficiencyLevel(element.pl).name;
          if(!element.plName) {
            element.plName = "None";
          }
        });

        this.competencyListData = sortedData;
        this.applyFilter(sortedData);
      }));
    this.verifyParams();
    this.observeFilterMyElement();
  }

  private async paginator() {
    const data = this.competencyUnits.slice((this.index) * this.itemsPage, (this.index) * this.itemsPage + this.itemsPage);
    if (data.length > 0) {
      this.isLoading = true;
      this.index++;
      await sleep(MILLISECONDS_LOAD_LIST)
    }

    this.isLoading = false;
  }

  private resetList() {
    this.index = 0;
    this.showDetail = null;
  }

  public observeFilterMyElement() {
    this.filterMyElementsService.onChange()
      .subscribe((data) => {
        if (data) {
          this.filtersDefault = [{ key: 'plName', value: data.value }];
        }
      });
  }

  public async verifyParams() {
    await sleep(MILLISECONDS_LOAD_LIST)
    this.route.queryParams.forEach((i) => {
      if (i.levelName) this.filterMyElementsService.setValue(i.levelName);
    })
  }

  private orderDaysRemaining(data) {
    data.sort((a, b) => {
      const daysRemainingA = a?.expirationDate;
      const daysRemainingB = b?.expirationDate;

      if (daysRemainingA == undefined && daysRemainingB != undefined) return 1;
      if (daysRemainingB == undefined && daysRemainingA != undefined) return -1;
      if (daysRemainingA > daysRemainingB) return 1;
      if (daysRemainingA < daysRemainingB) return -1;

      return 0;
    });
    return data;
  }

  public applyFilter(filteredData) {
    this.competencyList = filteredData;
    this.resetList();
    this.paginator();
  }

  public findProficiencyLevel(level: number): ProficiencyLevel {
    const pl = new ProficiencyLevel();
    pl.id = level;
    pl.setNameById(level);

    return pl;
  }

  public navigateToHome() {
    this.router.navigate(['home']);
  }

  get isMobileSize() {
    return isMobileSize()
  }

  public showElementsDetail(element: Competency) {
    if (this.showDetail?.competencyElement === element.competencyElement) {
      this.showDetail = null;
      this.elementDetailService.clear();
      return;
    }
    this.showDetail = element;
    if (!this.isMobileSize) {
      this.elementDetailService.clearDataTypePCE();
      this.elementDetailService.setValue(element);
    }
  }

  public onScroll() {
    this.paginator()
  }
}
