import { Training } from './../../../../../../shared/models/competency-details.model';
import { Component, Input } from '@angular/core';
import { isMobileSize } from '~/shared/utils/navigator';
import { MatIconModule} from '@angular/material/icon';
import { Configuration } from '../../../../../../shared/models/configuration.model';


@Component({
  selector: 'pcp-training-content',
  templateUrl: './training-content.component.html',
  styleUrls: ['./training-content.component.scss']
})
export class TrainingContentComponent {
  endpoint: string;

  constructor(    
    config: Configuration,        
  ) {    
    this.endpoint = config.urlCreateTicketiLearn;
  }  
  @Input() training: Training;
  @Input() pathwayCompleted: boolean;

  checkIfComponentShouldUseMobileSize() {
    return isMobileSize();
  }

  showTrainingTemplate(): boolean {
    return  this.training?.groups.some(g => g.subGroups?.some(sg => (sg.contents?.length ?? 0) > 0 || (sg.invalidContents?.length ?? 0) > 0));
  }
}
