<div class="compliance-average-progress-bar-wrapper">
  <div class="progress-bar-container compliance-average-progress-bar">
      <div class="progress-bar-label-container">
        <span class="">Job Compliance</span>
        <span class="progress-bar-label-percentage ">{{lastSixMonthsAverage}}%</span>
      </div>
      <mat-progress-bar class="progress-bar average-progress-bar-last-months"
        [value]="lastSixMonthsAverage">
      </mat-progress-bar>
  </div>
</div>
