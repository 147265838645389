import { AssessmentStatus } from "../enums/assessment-status.enum";

export class AssessmentDetails {
  public numberOfQuestions: number;
  public questionsAnswered: number;
  public expirationPercentage: number;
  public completedPercentage: number;
  public id: number;
  public status: AssessmentStatus;
  public assesseeGin: number;
  public assesseeName: string;
  public assessorGin: number;
  public assessorName: string;
  public ceId: number;
  public ceName: string;
  public pl: number;
  public tasks: [];
  public metadata: [];

  constructor(data?: Partial<AssessmentDetails>) {
      Object.assign(this, data);
  }
}
