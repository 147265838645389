import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { MSAL_INSTANCE, MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { environment } from './environments/environment';
import { AppModule } from './app/app.module';
import { MsalConfig } from './app/configs/msal-config';
import { Configuration } from './app/shared/models/configuration.model';

if (environment.env=="prod") {
  enableProdMode();
}

platformBrowserDynamic([
  {
    provide: MSAL_INSTANCE,
    useValue: MsalConfig.getMsalInstanceConfig(useEnvironmentOrConfig())
  },
  {
    provide: MSAL_GUARD_CONFIG,
    useValue: MsalConfig.getMsalConfig()
  },
  {
    provide: MSAL_INTERCEPTOR_CONFIG,
    useValue: MsalConfig.getMsalInterceptorConfig(useEnvironmentOrConfig())
  },
  {
    provide: Configuration,
    useValue: useEnvironmentOrConfig()
  }
])
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

function useEnvironmentOrConfig(): any {
  return environment;
}
