import { Component, Input } from '@angular/core';

@Component({
  selector: 'pcp-element-completed-template',
  templateUrl: './element-completed-template.component.html',
  styleUrls: ['./element-completed-template.component.scss']
})
export class ElementCompletedTemplateComponent {
  @Input() showQuantityProgress: boolean = false;
  @Input() showNoDataAvailable: boolean = false;
  @Input() quantityCompleted: number = 0;
  @Input() quantityRequired: number = 0;
  @Input() certs: Array<any>;
  @Input() sources: Array<any>;
}
